import { Helmet } from "react-helmet";
import React, { useState } from "react";

import Sitelogo from "../img/logo.svg";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    baseApiUrl,
    ForgotPass
} from "./const";


const Forgotpass = (props) => {
    const [Email, setEmail] = useState('');
    const [validate, setvalidate] = useState(false);
    const { t } = useTranslation();

    const forgotUser = () => {
        const validation = checkEmptyField(Email)

        if (!validation) {
            setvalidate(true)
        }
        else {
            const data = { email: Email }
            fetch(baseApiUrl + ForgotPass, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }).then((response) => {
                return response.json().then((res) => {
                    if (res.success === true) {
                        Swal.fire("Success!", res.message, "success").then((value) => {
                        });
                    } else {
                        Swal.fire("Failed!", res.message, "error");
                    }
                });
            });
        }
    }

    const EmailEmptyCheck = (val) => {
        return val === '' ? t('Lang.This is a required field.') : ''
    }

    const checkEmptyField = (val) => {
        let validationStatus = true;
        if (val === '' || val === undefined) {
            validationStatus = false;
        }
        return validationStatus
    }

    return (
        <>
            <Helmet>
                {/* <title>'Forgotpass'</title> */}
            </Helmet>

            <div className="forgot-page box_wrap_info">
                <div className="container">
                    <div className="row justify-content-center m-0">
                        <div className="bg-white login_form">
                            <div className="row m-0 p-0">
                                <div className="col-md-12 p-0">
                                    <div className="form-left">
                                        <div className="text-center">
                                            <img src={Sitelogo} alt="" className="logo" />
                                        </div>

                                        <div className="d-flex align-items-center flex-wrap p-0 justify-content-between">
                                            <div className="col-md-12 p-0">
                                                <form
                                                    id="login-form"
                                                    action=""
                                                    className="row m-0 p-0">
                                                    <div className="col-12 p-0 m_10">
                                                        <div className="input-group">
                                                            <input
                                                                value={Email}
                                                                type="Email"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                className="form-control"
                                                                placeholder="Enter Email"
                                                            />

                                                        </div>
                                                        <span className="invalid-feedback d-block">{validate ? EmailEmptyCheck(Email) : ''}</span>
                                                    </div>

                                                    <div className="col-md-12 p-0 m_10">
                                                        <Link to='/' className="float-end text_forgot">
                                                            Back to login
                                                        </Link>
                                                    </div>


                                                    <div className="col-sm-12 p-0">
                                                        <Button
                                                            onClick={() => {
                                                                forgotUser()
                                                            }}
                                                            type="button"
                                                            className="add-new-field"
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Forgotpass;