import React, { useState } from "react";
import ServiceConfiguration from "./Config_Service";
import ColumnConfiguration from "./Config_Column";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
// Import
import "react-confirm-alert/src/react-confirm-alert.css";

// Default Funcation Call
const Configuration = () => {

  const { t } = useTranslation();
  let [pagetitle] = useState(() => t('Lang.Form360') + ' - ' + t('Lang.Configuration'));


  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>

      <div className="col-sm p-3 min-vh-100 main-listing">

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-3">
            <li className="breadcrumb-item active" aria-current="page">
              {" "}
              {t('Lang.Configuration')}{" "}
            </li>
          </ol>
        </nav>

        <ul
          className="nav nav-tabs configuration-tabs"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              {t('Lang.Column fields')}
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              {t('Lang.Service tasks')}
            </button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div
            className="rounded-3 tab-pane fade show active tab-white p-0"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <ColumnConfiguration />
          </div>


          <div
            className="rounded-3 tab-pane configuration__page fade tab-white p-0"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <ServiceConfiguration />
          </div>
        </div>
      </div>
    </>
  );
};

export default Configuration;
