import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserIcon from "../img/icon_user.svg";
import Sitelogo from "../img/logo.svg";
import NotifiIcon from "../img/top_iconalert.svg";
import logout from "../img/logout.svg";
import {
  NotificationCount
} from "./const";
import { WScalling } from "./Apicalling";
import {
  baseApiUrl,
  UserLogin
} from "./const";
import { useTranslation } from 'react-i18next';

const Navbar = () => {

  let navigate = useNavigate();
  const [userDetail] = useState(JSON.parse(localStorage.getItem('userdetail')));
  const [Noticount, setNoticount] = useState("0");
  const [companyid] = useState({ company_id: userDetail.company_id });
  const [admin_status] = useState(JSON.parse(localStorage.getItem('SuperAdmin')));
  const [admin_id] = useState(localStorage.getItem('SuperAdmin_id'));
  const {i18n } = useTranslation();

  useEffect(() => {
    // All Field Api Calling (Show All Field to Table Listing)

    const noticount = JSON.parse(localStorage.getItem('noticount'))
    if (noticount === null) {
      WScalling(baseApiUrl + NotificationCount, "post", companyid, userDetail.token).then((response) => {
        localStorage.setItem('noticount', JSON.stringify(response.data.count));
        setNoticount(response.data.count)
      });
    }
    else {
      setNoticount(noticount)
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('userdetail')
    localStorage.removeItem('Obj_Pagination')
    localStorage.removeItem('MH_Pagination')
    navigate('/');

  };

  const SuperAdminApi = () => {

    if (admin_status) {
      WScalling(baseApiUrl + UserLogin, "post", { id: admin_id }, userDetail.token).then((response) => {
        i18n.changeLanguage(response.data.lang);
        localStorage.setItem('userdetail', JSON.stringify(response.data));
        localStorage.setItem('SuperAdmin', false);

        WScalling(baseApiUrl + NotificationCount, "post", { company_id: response.data.company_id }, response.data.token).then((notificationResponse) => {
          localStorage.setItem('noticount', JSON.stringify(notificationResponse.data.count));
          setNoticount(notificationResponse.data.count);
            navigate('/home');
          window.location.reload();
        });
      });
    }
    else {
      handleLogout()
    }
  }


  return (
    <>
      <div className="top-header d-flex flex-md-nowrap flex-wrap justify-content-between container-fluid align-items-center p-sm-2 px-2 py-1">
        <div className="logo">
          <Link to="/home
        ">
            <img src={Sitelogo} alt="" className="sitelogo" />
          </Link>
        </div>

        <nav className="header-nav ms-sm-auto">
          <ul className="d-flex align-items-center m-0 list-unstyled">

            <li className="nav-item d-flex align-items-center">
              <div className="nav-profile d-flex align-items-right pe-0 me-3 flex-column">
                <p className="user-name m-0">{userDetail.name}</p>
                <span className="user-tagline">{userDetail.company_name}</span>
              </div>
              <img src={UserIcon} alt="" className="user-icon" />
            </li>

            <li className="nav-item mx_20">
              <Link
                to="/notify"
                className="nav-link nav-icon position-relative px-0"

              // data-bs-toggle="dropdown"
              // aria-expanded="false"
              >
                <img className="notify" src={NotifiIcon} alt="" />
                <span className="badge bg-primary badge-number notification-counter">
                  {Noticount}
                </span>
              </Link>

              {/* <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li className="dropdown-header">
                  You have 4 new notifications
                  <a href="/">
                    <span className="badge rounded-pill bg-primary p-2 ms-2">
                      View all
                    </span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>30 min. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="notification-item">
                  <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>30 min. ago</p>
                  </div>
                </li>
              </ul> */}
            </li>

            <li className="nav-item pe-0 d-flex">
              <img
                onClick={() => {
                  SuperAdminApi()
                }
                } src={logout} alt="" className="user-icon logout" />
            </li>

          </ul>
        </nav>

      </div>
    </>
  );
}

export default Navbar;
