import React, { useCallback, useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Delete, Menu } from "@mui/icons-material";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { WScalling } from "./Apicalling";

import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';

import {
  baseApiUrl,
  DeleteTaskdAPI,
  AllTaskFieldAPI,
  TaskCreateAPI,
  SortTaskdAPI,
  EditTaskAPI
} from "./const"; // Import css


export let userData = [];

const ServiceConfiguration = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState(() => userData);
  const [validationErrors, setValidationErrors] = useState({});
  let [EditMode, setEditMode] = useState(false);
  const [arrdata, setarrdata] = useState(() => []);
  const [errors, setErrors] = useState("");
  const [designation, setDesignation] = useState("");
  const [row, setrow] = useState("");
  const [userDetail] = useState(JSON.parse(localStorage.getItem('userdetail')));
  const { t } = useTranslation();
  const [rolename] = useState(userDetail.role_name === "User" ? true : null);
  let [lastPosition, setlastPosition] = useState(0);

  let [checkBoxValue, setCheckBoxValue] = useState([
    { Option: "Option1", value: "" },
    { Option: "Option2", value: "" },
  ]);

  const [forminitialval] = useState({ title: '', is_required: '0', optionValues: checkBoxValue, placeholder: '', unit: '', status: '0' })

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
      };
    },
    [validationErrors]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t('Lang.ID'),
        enableColumnOrdering: false,
        size: 150,
      },
      {
        accessorKey: "status",
        header: t('Lang.Status'),
        size: 20,
        Cell: ({ renderedCellValue, row }) => (
          <span
            className={renderedCellValue === "1" ? "active" : "inactive"}
          ></span>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "name",
        header: t('Lang.Name'),
        enableColumnOrdering: false,
        size: 150,
      },
      {
        accessorKey: "type",
        header: t('Lang.Type'),
        size: 140,
        Cell: ({ renderedCellValue, row }) => (
          <label>{t('Lang.' + renderedCellValue)}</label>
        ),
        editVariant: "select",
        editSelectOptions: [
          {
            value: "alphanumeric",
            text: "Alphanumeric",
          },
          {
            value: "checkbox",
            text: "Checkbox",
          },
          {
            value: "dropdown",
            text: "Dropdown",
          },
          {
            value: "freeform_text",
            text: "Freeform Text",
          },
          {
            value: "value",
            text: "Value",
          },
        ],

      },
      {
        accessorKey: "placeholder_text",
        header: t('Lang.Placeholder'),

        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "is_required",
        header: t('Lang.Required'),
        size: 140,
        enableColumnOrdering: false,
        Cell: ({ renderedCellValue, row }) => (
          <label className="black">{renderedCellValue === "1" ? t('Lang.Yes') : t('Lang.No')}</label>
        ),

        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),

        }),
      },
      {
        accessorKey: "createdOn",
        header: t('Lang.Created on'),
        size: 130,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "string",
        }),
      },
      {
        accessorKey: "createdBy",
        header: t('Lang.Created by'),
        Cell: ({ renderedCellValue, row }) => (
          <label className="black">{t('Lang.' + renderedCellValue)}</label>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "string",
          size: 120,
        }),
      },
    ],
    [getCommonEditTextFieldProps]
  );

  useEffect(() => {

    const apidata = {
      company_id: userDetail.company_id
    }

    WScalling(baseApiUrl + AllTaskFieldAPI, "post", apidata, userDetail.token).then((resp) => {
      setarrdata(resp.data);

      Object.values(resp.data).map(function (val) {
        if (val.order >= lastPosition) {
          lastPosition = val.order;
        }
      });

      setlastPosition(lastPosition);
      setTableData(resp.data);
    });
  }, []);



  // CREATE/ADD NEW ROW ON SAVE BUTTON CLICK
  const handleCreateNewRow = (value) => {

    const data = {
      type: designation,
      name: value.title,
      placeholder_text: value.placeholder,
      option_values: designation === "value" ? [value.unit] : value.optionValues,
      order: EditMode ? 9999 : lastPosition + 1,
      status: EditMode ? value.status : "1",
      created_by: EditMode ? "1" : userDetail.id,
      company_id: userDetail.company_id,
      is_required: value.is_required,
    };

    if (EditMode) {
      WScalling(baseApiUrl + EditTaskAPI + row.getValue("id"), "put", data, userDetail.token).then((resp) => {
        tableData[row.index] = resp.data;
        setTableData([...tableData]);
        setarrdata([...tableData]);
        setCreateModalOpen(false);
      });
    }
    else {
      WScalling(baseApiUrl + TaskCreateAPI, "post", data, userDetail.token).then((resp) => {
        setlastPosition(lastPosition + 1);
        tableData.push(resp.data);
        setTableData([...tableData]);
        setarrdata([...tableData]);
        setCreateModalOpen(false);
      });
    }
  }

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  // DELETE ROW ON DELETE BUTTON CLICK
  const handleDeleteRow = useCallback(
    (row) => {
      confirmAlert({
        title: t("Lang.Delete task"),
        message: <div>{t('Lang.Delete custom field')}<br />"{row.getValue("name")}"<br /> {'?'}</div>,
        buttons: [
          {
            label: t('Lang.Yes, delete task'),
            className: "yes-confirm",
            onClick: () => {
              WScalling(
                baseApiUrl + DeleteTaskdAPI + row.getValue("id"),
                "delete", "", userDetail.token
              ).then((resp) => {
                tableData.splice(row.index, 1);
                setTableData([...tableData]);
                setarrdata([...tableData]);
              });
            },
          },
          {
            label: <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3495 9.99858L19.5103 2.85447C19.8238 2.54089 20 2.11558 20 1.67211C20 1.22864 19.8238 0.80333 19.5103 0.489749C19.1967 0.176168 18.7714 0 18.3279 0C17.8844 0 17.4591 0.176168 17.1455 0.489749L10.0014 7.65052L2.85731 0.489749C2.54373 0.176168 2.11842 -3.30411e-09 1.67495 0C1.23148 3.30411e-09 0.806172 0.176168 0.492591 0.489749C0.17901 0.80333 0.00284199 1.22864 0.00284199 1.67211C0.00284199 2.11558 0.17901 2.54089 0.492591 2.85447L7.65336 9.99858L0.492591 17.1427C0.336506 17.2975 0.212618 17.4817 0.128073 17.6846C0.0435283 17.8875 0 18.1052 0 18.3251C0 18.5449 0.0435283 18.7626 0.128073 18.9655C0.212618 19.1684 0.336506 19.3526 0.492591 19.5074C0.647402 19.6635 0.831585 19.7874 1.03452 19.8719C1.23745 19.9565 1.45511 20 1.67495 20C1.89479 20 2.11245 19.9565 2.31538 19.8719C2.51832 19.7874 2.7025 19.6635 2.85731 19.5074L10.0014 12.3466L17.1455 19.5074C17.3003 19.6635 17.4845 19.7874 17.6875 19.8719C17.8904 19.9565 18.1081 20 18.3279 20C18.5477 20 18.7654 19.9565 18.9683 19.8719C19.1713 19.7874 19.3554 19.6635 19.5103 19.5074C19.6663 19.3526 19.7902 19.1684 19.8748 18.9655C19.9593 18.7626 20.0028 18.5449 20.0028 18.3251C20.0028 18.1052 19.9593 17.8875 19.8748 17.6846C19.7902 17.4817 19.6663 17.2975 19.5103 17.1427L12.3495 9.99858Z" fill="white"></path></svg>,
            className: "no-confirm",
            onClick: () => {
              return;
            },
          },
        ],
      });
    },
    [tableData]
  );


  const showFiled = (Type, row) => {
    forminitialval.title = row.getValue("name")
    forminitialval.is_required = row.original.is_required
    forminitialval.status = row.original.status

    if (Type === "dropdown") {
      forminitialval.optionValues = row.original.option_values;
    }
    else if (Type === "freeform_text") {
      forminitialval.placeholder = row.getValue("placeholder_text");
    }
    else if (Type === "value") {
      forminitialval.placeholder = row.getValue("placeholder_text");
      forminitialval.unit = row.original.option_values[0];
    }
  };

  return (
    <>
      <div className="tab-content" id="myTabContent">
        <div
          className="rounded-3 tab-pane fade show active tab-white p-0"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div className="App mt-0">

            <div className="configure-section configure_page col-sm">
              <MaterialReactTable
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    muiTableHeadCellProps: {
                      align: 'center',
                    },
                    size: 120
                  },
                }}

                localization={{
                  actions: t('Lang.Actions'),
                  noRecordsToDisplay: t('Lang.No records to display'),
                  noResultsFound: t('No results found'),
                }}

                columns={columns}
                data={tableData}
                muiTableBodyProps={{
                  sx: {
                    //stripe the rows, make odd rows a darker color
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#f5f5f5",
                    },
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: "0 solid #e0e0e0", //add a border between columns
                  },
                }}
                editingMode="modal" //default
                enableRowOrdering
                enableRowDragging
                enableSorting={true}
                enableColumnFilters={false}
                onEditingRowCancel={handleCancelRowEdits}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableEditing={userDetail.role_name === 'User' ? false : true} // If Set False then action column hide.
                initialState={{
                  density: "compact",
                  showGlobalFilter: true,
                  columnVisibility: { id: false, order: false },
                }}
                enableGlobalFilterModes
                positionGlobalFilter="left"
                enablePagination={false}
                enableColumnActions={false}
                positionActionsColumn="last"
                enableBottomToolbar={false}
                muiSearchTextFieldProps={{
                  placeholder: t('Lang.Search...'),
                }}

                icons={{
                  DragHandleIcon: () => <IconButton className="menu_drop"> <Menu /> </IconButton>,
                  MoreVertIcon: () => null
                }}

                muiTableBodyRowDragHandleProps={({ table }) => ({
                  onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    if (draggingRow) {
                      tableData.splice(
                        hoveredRow.index,
                        0,
                        tableData.splice(draggingRow.index, 1)[0]
                      );

                      setTableData([...tableData]);
                      setarrdata([...tableData]);

                      const datas = {
                        id: draggingRow.getValue("id"),
                        order: hoveredRow.index + 1,
                        data: tableData,
                      };

                      WScalling(baseApiUrl + SortTaskdAPI, "post", datas, userDetail.token).then(
                        (resp) => {
                        }
                      );
                    }
                  },
                })}

                renderRowActions={({ row, table }) => (
                  <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center", alignItems: 'center' }}>
                    <Tooltip arrow placement="left" title={t('Lang.Edit')}>
                      <IconButton
                        className="edit-btn p-0"
                        onClick={() => {
                          setrow(row);
                          setDesignation(row.original.type_title);
                          showFiled(row.original.type_title, row);
                          setEditMode(true);
                          setCreateModalOpen(true);

                        }}
                      >
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.595 2.4706L9.53255 0.408153C9.26338 0.155313 8.91065 0.0102366 8.54148 0.00052145C8.1723 -0.0091937 7.81243 0.11713 7.53033 0.355463L0.75588 7.12991C0.512576 7.37527 0.361084 7.69686 0.326832 8.0407L0.00316363 11.1795C-0.00697624 11.2898 0.00732941 11.4009 0.0450607 11.505C0.0827921 11.6091 0.14302 11.7036 0.221451 11.7817C0.291785 11.8515 0.375198 11.9067 0.466908 11.9441C0.558617 11.9816 0.656818 12.0006 0.75588 12H0.823625L3.96245 11.714C4.30629 11.6797 4.62788 11.5282 4.87324 11.2849L11.6477 4.51046C11.9106 4.23268 12.0527 3.862 12.0428 3.47964C12.033 3.09728 11.8719 2.73443 11.595 2.4706ZM9.03576 4.98467L7.01848 2.96739L8.48628 1.46196L10.5412 3.51687L9.03576 4.98467Z" fill="#999999" />
                        </svg>
                        <span className="ms-1">{t('Lang.Edit')}</span>
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="right" title={t('Lang.Delete')}>
                      <IconButton
                        className="delete-btn p-0"
                        color="error"
                        onClick={() => handleDeleteRow(row)}
                      >
                        <Delete />
                        <span>{t('Lang.Delete')}</span>
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                options={{
                  renderRowActionsColumnIndex: -1,
                  tableLayout: "fixed"
                }}

                renderTopToolbarCustomActions={() => (
                  <>

                    <div className="d-flex justify-content-start align-items-center w-md-auto ms-sm-2">

                      <h4 className="user">{t('Lang.Show only active fields')}</h4>

                      <Switch
                        name="status"
                        onChange={(event) => {
                          let arr = [];
                          if (event.target.checked === true) {
                            arr = arrdata.filter((val) => "1" === val.status);
                          } else {
                            arr = arrdata;
                          }
                          setTableData(arr);
                        }}
                      />
                    </div>

                    {
                      rolename ? null :
                        <Button
                          color="secondary"
                          className="add-new-field ms-auto"
                          onClick={() => {
                            setEditMode(false);
                            setCreateModalOpen(true);
                            setDesignation("");
                            forminitialval.title = ""
                          }}
                          variant="contained"
                        >
                          {t('Lang.Add new task')}
                        </Button>
                    }
                  </>
                )}
              />
            </div>


            <EditFieldPopup
              checkBoxValue={checkBoxValue}
              setCheckBoxValue={setCheckBoxValue}
              designation={designation}
              setDesignation={setDesignation}
              errors={errors}
              columns={columns}
              open={createModalOpen}
              onClose={() => setCreateModalOpen(false)}
              onSubmit={handleCreateNewRow}
              EditMode={EditMode}
              forminitialval={forminitialval}
            />
          </div>
        </div>
      </div>
    </>
  );
};



function EditFieldPopup(props) {
  const {
    forminitialval,
    open,
    columns,
    onClose,
    onSubmit,
    EditMode,
    errors,
    designation,
    setDesignation,
    checkBoxValue,
    setCheckBoxValue,
  } = props;

  const { t } = useTranslation();
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {})
  );


  const onfillup = (values) => {
    onSubmit(values);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('Lang.This is a required field.')),
  });

  const handlePop = (optionValuesObj, i) => {
    optionValuesObj.values.optionValues.splice(i, 1);
    setValues(optionValuesObj);
  };

  return (

    <Dialog open={open} className="d-w-400 popup_gape">
      <div className="popup-header d-flex justify-content-between">
        <DialogTitle textAlign="center" className="p-3">
          {EditMode ? t('Lang.Edit task') : t('Lang.Add new task')}
        </DialogTitle>{" "}
        <Button onClick={onClose}>
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3495 9.99858L19.5103 2.85447C19.8238 2.54089 20 2.11558 20 1.67211C20 1.22864 19.8238 0.80333 19.5103 0.489749C19.1967 0.176168 18.7714 0 18.3279 0C17.8844 0 17.4591 0.176168 17.1455 0.489749L10.0014 7.65052L2.85731 0.489749C2.54373 0.176168 2.11842 -3.30411e-09 1.67495 0C1.23148 3.30411e-09 0.806172 0.176168 0.492591 0.489749C0.17901 0.80333 0.00284199 1.22864 0.00284199 1.67211C0.00284199 2.11558 0.17901 2.54089 0.492591 2.85447L7.65336 9.99858L0.492591 17.1427C0.336506 17.2975 0.212618 17.4817 0.128073 17.6846C0.0435283 17.8875 0 18.1052 0 18.3251C0 18.5449 0.0435283 18.7626 0.128073 18.9655C0.212618 19.1684 0.336506 19.3526 0.492591 19.5074C0.647402 19.6635 0.831585 19.7874 1.03452 19.8719C1.23745 19.9565 1.45511 20 1.67495 20C1.89479 20 2.11245 19.9565 2.31538 19.8719C2.51832 19.7874 2.7025 19.6635 2.85731 19.5074L10.0014 12.3466L17.1455 19.5074C17.3003 19.6635 17.4845 19.7874 17.6875 19.8719C17.8904 19.9565 18.1081 20 18.3279 20C18.5477 20 18.7654 19.9565 18.9683 19.8719C19.1713 19.7874 19.3554 19.6635 19.5103 19.5074C19.6663 19.3526 19.7902 19.1684 19.8748 18.9655C19.9593 18.7626 20.0028 18.5449 20.0028 18.3251C20.0028 18.1052 19.9593 17.8875 19.8748 17.6846C19.7902 17.4817 19.6663 17.2975 19.5103 17.1427L12.3495 9.99858Z" fill="white"></path></svg>
        </Button>
      </div>
      <DialogContent>


        <Formik
          initialValues={forminitialval}
          onSubmit={onfillup}
          validationSchema={validationSchema}
        >

          {({ values, handleChange, setFieldValue }) => (

            <Form className="add--fields">
              <Stack
                sx={{
                  width: "100%",
                  minWidth: { xs: "300px", sm: "360px", md: "400px" },
                  gap: "1.5rem",
                }}
              >

                {/* Status */}
                {EditMode ? (
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">{t('Lang.Status')}</label>
                    <div className=" col-sm-6 ">
                      <div className="form-check form-switch text-end mb-0">
                        <Switch
                          checked={values.status === '1' ? true : false}
                          name="status"
                          onChange={(event) => {
                            setFieldValue(`status`, event.target.checked ? "1" : "0")
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}


                <div className="form-group row mx-0">
                  <h5 className="text-center title-select mb-3">
                    {EditMode === false ? t('Lang.Please select type of task') : null}
                  </h5>

                  <label className="col-sm-5 col-form-label px-0">{t('Lang.Type')}</label>
                  <div className="control col-sm-7 pe-0">
                    <select
                      disabled={EditMode}
                      className="form-control select-dropdown"
                      id="designation"
                      name="type"
                      value={designation}
                      type="text"
                      onChange={(e) => {

                        values.status = "0"
                        values.is_required = "0"
                        values.optionValues = [
                          { Option: "Option1", value: "" },
                          { Option: "Option2", value: "" },
                        ]
                        values.unit = ""
                        values.placeholder = ""
                        // setFieldValue(`title`, '')
                        setDesignation(e.target.value);
                      }}
                      placeholder={t('Lang.Select field')}
                      required
                    >

                      <option disabled={true} value="" defaultValue>{t('Lang.Select any task')}</option>

                      <option className="odd" value="alphanumeric">
                        {t('Lang.Alphanumeric')}
                      </option>
                      <option className="even" value="checkbox">
                        {t('Lang.Checkbox')}
                      </option>
                      <option className="odd" value="dropdown">
                        {t('Lang.Dropdown')}
                      </option>
                      <option className="even" value="freeform_text">
                        {t('Lang.Freeform Text')}
                      </option>
                      <option className="odd" value="value">
                        {t('Lang.Value')}
                      </option>
                    </select>
                  </div>
                </div>



                {/* Title Here showing */}
                {designation !== '' && (
                  <>
                    <div className="form-group row mx-0">
                      <label className="col-sm-5 px-0 col-form-label">{t('Lang.Title')}</label>
                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                        <Field
                          autocomplete="off"
                          id="title"
                          name="title"
                          className="form-control"
                          type="text"
                          // value={title}
                          placeholder={
                            (designation === 'alphanumeric') ? t('Lang.Enter title') :
                              (designation === 'checkbox' || designation === 'dropdown') ? t('Lang.Used for') :
                                (designation === 'freeform_text' || designation === 'value') ? t('Lang.Enter title here...') :
                                  ''
                          }
                          onChange={handleChange}
                        />
                        <ErrorMessage className="invalid-feedback d-block" name="title" component="div" />
                      </div>
                    </div>
                  </>
                )}



                {/* dropdown multiple */}
                {(designation === 'dropdown') && (
                  <>
                    {
                      values.optionValues.map((element, index) => (
                        <div className="form-group row mx-0">
                          <label className="col-sm-5 col-form-label px-0">
                            {t('Lang.Option')} {index + 1}
                          </label>

                          <div className="col-sm-7 pe-0 ps-sm-2 ps-0 options-block">
                            <Field
                              className="form-control"
                              placeholder={t('Lang.Enter option')}
                              name={`optionValues.${index}.value`}
                              type="text"
                              // value={checkBoxValue[index].value}
                              onChange={handleChange}
                            />
                            {index > 1 && (
                              <Delete 
                                className="delete-btn option-delete-btn p-0 deletebtn"
                                onClick={() => handlePop({values}, index)}
                                />
                            )}
                          </div>
                        </div>
                      ))
                    }

                    <div className="form-group row mx-0">
                      <label className="col-sm-5 col-form-label px-0"></label>
                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                        <Button
                          color="secondary"
                          className="add-new-field w-100"
                          variant="contained"
                          onClick={() => {
                            values.optionValues.push({ Option: "Option" + (values.optionValues.length + 1), value: "" })
                            setCheckBoxValue([...checkBoxValue, { Option: "", value: "" }]);
                          }}
                        >
                          + {t('Lang.Add option')}
                        </Button>
                      </div>
                    </div>
                  </>
                )}



                {/* placeholder Here showing */}
                {(designation === 'freeform_text' || designation === 'value') && (
                  <>
                    <div className="form-group row mx-0">
                      <label className="col-sm-5 px-0 col-form-label">{t('Lang.Placeholder Text')}</label>

                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                        <Field
                          id="placeholder"
                          name="placeholder"
                          className="form-control"
                          type="text"
                          // value={title}
                          placeholder={t('Lang.Enter placeholder text')}
                          onChange={handleChange}
                        />
                        <span className="invalid-feedback d-block">{errors}</span>
                      </div>

                    </div>
                  </>
                )}



                {/* Unit Here showing */}
                {(designation === 'value') && (
                  <>
                    <div className="form-group row mx-0">
                      <label className="col-sm-5 px-0 col-form-label">{t('Lang.Unit')}</label>

                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                        <Field
                          id="unit"
                          name="unit"
                          className="form-control"
                          type="text"
                          // value={title}
                          value={values.unit}
                          placeholder={t('Lang.Enter placeholder text')}
                          onChange={handleChange}
                        />
                        <span className="invalid-feedback d-block">{errors}</span>
                      </div>
                    </div>
                  </>
                )}



                {/* is_required */}
                {designation !== '' && (
                  <div className="form-group row mx-0">
                    <label className="col-sm-5 col-form-label px-0">
                      {t('Lang.Required (Setup)')}
                    </label>
                    <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                      <div className="form-check form-switch text-sm-end mb-0 p-0">
                        <Switch
                          checked={values.is_required === '1' ? true : false}
                          name="is_required"
                          onChange={(event) => {
                            setFieldValue(`is_required`, event.target.checked ? "1" : "0")
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}


                <button
                  variant="contained"
                  color="secondary"
                  className="add-new-field w-100"
                  type="submit">
                  {EditMode ? t('Lang.Update') : t('Lang.Save')}
                </button>


              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>

    </Dialog>

  );
}


export default ServiceConfiguration;

