import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { MaterialReactTable } from "material-react-table";
import { Crmadmins } from "./Crmadmins";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Switch,
} from "@mui/material";
import { Delete, Person } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Form from 'react-bootstrap/Form';
import { WScalling } from "./Apicalling";
import { useTranslation } from 'react-i18next';

import {
  baseApiUrl,
  UserLogin,
  UserList,
  UserCreate,
  UserDelete,
  UserUpdate,
  Roles,
  Compuny,
  NotificationCount
} from "./const";
import { Company } from './Company';
export let userData = [];


export default function Settingpage() {

  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [passwordShown, setPasswordShown] = useState(false);
  const [nameErrors, setNameErrors] = useState(false)
  const [surnameErrors, setSurnameErrors] = useState(false)
  const [emailErrors, setEmailErrors] = useState(false)
  const [passwordErrors, setPasswordErrors] = useState(false)
  const [repeatpasswordErrors, setRepeatpasswordErrors] = useState(false)
  const [companyErrors, setCompanyErrors] = useState(false)

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState(() => userData);
  const [validationErrors, setValidationErrors] = useState({});
  let [EditMode, setEditMode] = useState(false);
  const [selectfieldStatus, setfieldStatus] = useState(false);
  const [showName, setName] = useState(false);
  const [showSurname, setSurname] = useState(false);
  const [showEmail, setEmail] = useState(false);
  const [showPassword, setPassword] = useState('');
  const [showRepeatPassword, setRepeatpassword] = useState(false);
  const [showCompany, setCompany] = useState(false);
  const [showRole, setRole] = useState(1);
  const [showLanguage, setLanguage] = useState('en');
  const [rowid, setrowid] = useState("");
  const [row, setRow] = useState("");
  const [arrdata, setarrdata] = useState(() => []);
  const [userDetail] = useState(JSON.parse(localStorage.getItem('userdetail')));
  const [rolename] = useState(userDetail.role_name === "User" ? true : null);
  const [showOption, setShowOption] = useState([{ 'name': '', 'id': '' }]);
  const [showRollOption, setShowRollOption] = useState([{ 'name': '', 'id': '' }]);
  let [pagetitle] = useState(() => t('Lang.Form360') + ' - ' + t('Lang.Settings'));
  const data = { company_id: userDetail.company_id, user: userDetail.id };
  const [Noticount, setNoticount] = useState("0");


  useEffect(() => {
    WScalling(baseApiUrl + UserList, "post", data, userDetail.token).then((resp) => {
      if (resp.success === true && resp.data !== "") {
        setarrdata(resp.data);
        setTableData(resp.data);
      }
    });
  }, []);


  const handleCreateNewRow = (values) => {
    const data = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      password: values.password,
      company_id: values.company_name,
      role_id: showRole,
      status: "1",
      lang: showLanguage,
      created_by: userDetail.id,
    };

    WScalling(baseApiUrl + UserCreate, "post", data, userDetail.token).then((resp) => {
      if (resp.success === true && resp.data !== "") {
        values.status = resp.data.status;
        values.createdOn = resp.data.createdOn;
        values.role_name = resp.data.role_name;
        values.company_name = resp.data.company_name;
        values.created_by = userDetail.name + ' ' + userDetail.surname;
        values.language = resp.data.lang;
        values.id = resp.data.id;
        values.company_id = resp.data.company_id;
        values.role_id = resp.data.role_id;
        tableData.push(values);
        setTableData([...tableData]);
      }
    });
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      setTableData([...tableData]);
      exitEditingMode();
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };


  const setAllUserBlank = () => {
    setName("")
    setSurname("")
    setEmail("")
    setPassword("")
    setRepeatpassword("")
    setCompany("")
  }

  const showFiled = (Company, row) => {
    setfieldStatus(row.original.status === "1" ? true : false);
    setName(row.original.name);
    setSurname(row.original.surname);
    setEmail(row.original.email);
    setLanguage(row.original.language);
    setrowid(row.getValue("id"));
  };

  const customerLoginApi = (rowid) => {
    localStorage.setItem('SuperAdmin_id', userDetail.id);

    WScalling(baseApiUrl + UserLogin, "post", { id: rowid }, userDetail.token).then((response) => {
      localStorage.setItem('userdetail', JSON.stringify(response.data));
      localStorage.setItem('SuperAdmin', true);
console.log('test', response.data);
      WScalling(baseApiUrl + NotificationCount, "post", { company_id: response.data.company_id }, response.data.token).then((notificationResponse) => {
        localStorage.setItem('noticount', JSON.stringify(notificationResponse.data.count));
        setNoticount(notificationResponse.data.count);
        // setTimeout(() => {
          navigate('/home');
        window.location.reload();
        // }, 1000);
    });

      i18n.changeLanguage(response.data.lang);
      // navigate('/home');
      // WScalling(baseApiUrl + NotificationCount, "post", { company_id: userDetail.company_id }, userDetail.token).then((notificationResponse) => {
        // localStorage.setItem('noticount', '1233');
        // setNoticount('1233');
    // });
      // window.location.reload();
    });
  };

  const handleDeleteRow = useCallback(
    (row) => {
      confirmAlert({
        title: t('Lang.Delete user'),
        message: t('Lang.Are you sure to do this.') + ' ' + `${row.getValue("name")}`,
        buttons: [
          {
            label: t('Lang.Yes'),
            onClick: () => {

              WScalling(baseApiUrl + UserDelete + row.getValue("id"), "delete", "", userDetail.token).then((resp) => {
                if (resp.success === true) {
                  tableData.splice(row.index, 1);
                  setTableData([...tableData]);
                  return;
                }
              });
            },
          },
          {
            label: t('Lang.No'),
            onClick: () => {
              return;
            },
          },
        ],
      });
    },
    [tableData],
  );

  const getCommonEditTextFieldProps = useCallback(

    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
      };
    },
    [validationErrors],
  );


  const getRoleAndCompuny = () => {

    WScalling(baseApiUrl + Compuny, "get", null, userDetail.token).then((resp) => {
      if (resp.success === true && resp.data !== "") {
        setShowOption(resp.data);
      }
    });

    WScalling(baseApiUrl + Roles, "get", null, userDetail.token).then((resp) => {
      if (resp.success === true && resp.data !== "") {
        setShowRollOption(resp.data);
      }
      setCreateModalOpen(true)
    });
  };


  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t('Lang.ID'),
        enableColumnOrdering: false,
        size: 150,
      },
      {
        accessorKey: 'status',
        header: t('Lang.Status'),
        size: 70,
        Cell: ({ renderedCellValue, row }) => (
          <span
            className={renderedCellValue === "1" ? "active" : "inactive"}
          ></span>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'company_name',
        header: t('Lang.Company'),
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'name',
        header: t('Lang.Name'),
        size: 100,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'surname',
        header: t('Lang.Surname'),
        size: 100,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'email',
        header: t('Lang.E-Mail'),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'email',
        }),
      },
      {
        accessorKey: 'role_name',
        header: t('Lang.Permissions'),
        size: 20,
        Cell: ({ renderedCellValue, row }) => (
          <label className="black">{t('Lang.' + renderedCellValue)}</label>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'createdOn',
        header: t('Lang.Created on'),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "number"
        }),
      },
      {
        accessorKey: 'created_by',
        header: t('Lang.Created by'),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },

      {
        accessorKey: 'language',
        header: t('Lang.Language'),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },

      {
        accessorKey: 'password',
        header: t('Lang.Password'),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'repeatpassword',
        header: t('Lang.Repeat Password'),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },


    ],
    [getCommonEditTextFieldProps],
  );


  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="col-sm p-3 min-vh-100 main-listing">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-3">
            <li className="breadcrumb-item active" aria-current="page">
              {" "}
              {t('Lang.Settings')}{" "}
            </li>
          </ol>
        </nav>

        <ul
          className="nav nav-tabs configuration-tabs"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              {t('Lang.Accounts')}
            </button>
          </li>

          {userDetail.id === 1 ? (
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                {t('Lang.CRM Administration')}
              </button>
            </li>
          ) : ''}


          {userDetail.id === 1 ? (
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="company-tab"
                data-bs-toggle="tab"
                data-bs-target="#company"
                type="button"
                role="tab"
                aria-controls="company"
                aria-selected="false"
              >
                {t('Lang.Company')}
              </button>
            </li>

          ) : ''}

        </ul>


        <div className="tab-content" id="myTabContent">
          <div
            className="rounded-3 setting__page tab-pane fade show active tab-white p-0"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="App mt-0">
              <div className="configure-section setting_page setting-section col-sm">
                <MaterialReactTable
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      muiTableHeadCellProps: {
                        align: 'center',
                      },
                    },
                  }}

                  localization={{
                    actions: t('Lang.Actions'),
                    noRecordsToDisplay: t('Lang.No records to display'),
                    noResultsFound: t('No results found'),
                  }}

                  columns={columns}
                  data={tableData}
                  muiTableBodyProps={{
                    sx: {
                      //stripe the rows, make odd rows a darker color
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#f5f5f5",
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      border: "0 solid #e0e0e0", //add a border between columns
                    },
                  }}

                  enableEditing={userDetail.role_name === 'User' ? false : true}
                  enableSorting={true}
                  positionActionsColumn="last"
                  enableColumnFilters={false}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableGlobalFilterModes
                  positionGlobalFilter="left"
                  enablePagination={false}
                  muiTablePaper={false}
                  enableBottomToolbar={false}
                  enableColumnActions={false}
                  onEditingRowSave={handleSaveRowEdits}
                  onEditingRowCancel={handleCancelRowEdits}
                  muiSearchTextFieldProps={{
                    placeholder: t('Lang.Search for user...'),
                    sx: { minWidth: '18rem' },
                    size: 'small',

                  }}

                  initialState=
                  {{
                    density: "compact",
                    showGlobalFilter: true,
                    columnVisibility: { id: false, language: false, password: false, repeatpassword: false }
                  }}

                  renderToolbarInternalActions={() => (
                    <>
                      <div className="d-flex align-items-center ms-sm-2">
                        <h4 className="user">{t('Lang.Show only active user')}</h4>
                        <Switch
                          name="status"
                          onChange={(event) => {
                            let arr = [];
                            if (event.target.checked === true) {
                              arr = arrdata.filter((val) => "1" === val.status);
                            } else {
                              arr = arrdata;
                            }
                            setTableData(arr);
                          }}
                        />
                      </div>

                    </>
                  )}


                  renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: "1rem", justifyContent: 'center' }}>
                      <Tooltip arrow placement="left" title={t('Lang.Edit')}>
                        <IconButton
                          className="edit-btn p-0"
                          onClick={() => {
                            setRow(row)
                            setrowid(row.original.id)
                            setEditMode(true)
                            setAllUserBlank();

                            setPasswordShown(false)
                            setCompanyErrors("");
                            setRepeatpasswordErrors("");
                            setPasswordErrors("");
                            setEmailErrors("");
                            setSurnameErrors("");
                            setNameErrors('')

                            getRoleAndCompuny()
                            setCompany(row.original.company_id)
                            setRole(row.original.role_id)
                            setLanguage(row.original.lang)
                            showFiled(row.getValue("company_name"), row);

                          }}
                        >
                          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.595 2.4706L9.53255 0.408153C9.26338 0.155313 8.91065 0.0102366 8.54148 0.00052145C8.1723 -0.0091937 7.81243 0.11713 7.53033 0.355463L0.75588 7.12991C0.512576 7.37527 0.361084 7.69686 0.326832 8.0407L0.00316363 11.1795C-0.00697624 11.2898 0.00732941 11.4009 0.0450607 11.505C0.0827921 11.6091 0.14302 11.7036 0.221451 11.7817C0.291785 11.8515 0.375198 11.9067 0.466908 11.9441C0.558617 11.9816 0.656818 12.0006 0.75588 12H0.823625L3.96245 11.714C4.30629 11.6797 4.62788 11.5282 4.87324 11.2849L11.6477 4.51046C11.9106 4.23268 12.0527 3.862 12.0428 3.47964C12.033 3.09728 11.8719 2.73443 11.595 2.4706ZM9.03576 4.98467L7.01848 2.96739L8.48628 1.46196L10.5412 3.51687L9.03576 4.98467Z" fill="#999999" />
                          </svg>
                          <span className="ms-1">{t('Lang.Edit')}</span>
                        </IconButton>
                      </Tooltip>

                      <Tooltip arrow placement="right" title={t('Lang.Delete')}>
                        <IconButton
                          className="delete-btn p-0"
                          color="error"
                          onClick={() => handleDeleteRow(row)}>
                          <Delete />
                          <span>{t('Lang.Delete')}</span>
                        </IconButton>
                      </Tooltip>

                      {
                        userDetail.id === 1 ?
                          <Tooltip arrow placement="left" title="Customer Login">
                            <IconButton
                              className="edit-btn p-0"
                              color="error"
                              onClick={() => customerLoginApi(row.original.id)}>
                              <Person />
                              <span>{t('Lang.Login as user')}</span>
                            </IconButton>
                          </Tooltip>
                          :
                          null
                      }

                    </Box>
                  )}

                  renderTopToolbarCustomActions={() => (

                    <>
                      {
                        rolename ? null :
                          <Button
                            className='add-new-field ms-auto'
                            sx={{ backgroundcolor: 'blue', marginLeft: 'auto', order: 1 }}
                            onClick={() => {

                              setPasswordShown(false)
                              setCompanyErrors("");
                              setRepeatpasswordErrors("");
                              setPasswordErrors("");
                              setEmailErrors("");
                              setSurnameErrors("");
                              setNameErrors('')
                              setEditMode(false)
                              getRoleAndCompuny();
                              setAllUserBlank();
                            }}
                            variant="contained"

                          >
                            {t('Lang.Add user')}
                          </Button>
                      }
                    </>
                  )}

                />
                <CreateNewAccountModal
                  nameErrors={nameErrors}
                  setNameErrors={setNameErrors}
                  surnameErrors={surnameErrors}
                  setSurnameErrors={setSurnameErrors}
                  emailErrors={emailErrors}
                  setEmailErrors={setEmailErrors}
                  passwordErrors={passwordErrors}
                  setPasswordErrors={setPasswordErrors}
                  repeatpasswordErrors={repeatpasswordErrors}
                  setRepeatpasswordErrors={setRepeatpasswordErrors}
                  companyErrors={companyErrors}
                  setCompanyErrors={setCompanyErrors}
                  columns={columns}
                  open={createModalOpen}
                  onClose={() => setCreateModalOpen(false)}
                  onSubmit={handleCreateNewRow}
                  EditMode={EditMode}
                  selectfieldStatus={selectfieldStatus}
                  setfieldStatus={setfieldStatus}
                  setName={setName}
                  showName={showName}
                  setSurname={setSurname}
                  showSurname={showSurname}
                  setEmail={setEmail}
                  showEmail={showEmail}
                  setPassword={setPassword}
                  showPassword={showPassword}
                  setRepeatpassword={setRepeatpassword}
                  showRepeatPassword={showRepeatPassword}
                  setCompany={setCompany}
                  showCompany={showCompany}
                  setRole={setRole}
                  showRole={showRole}
                  setLanguage={setLanguage}
                  showLanguage={showLanguage}
                  setAllUserBlank={setAllUserBlank}
                  setTableData={setTableData}
                  tableData={tableData}
                  rowid={rowid}
                  setCreateModalOpen={setCreateModalOpen}
                  row={row}
                  userDetail={userDetail}
                  setShowRollOption={setShowRollOption}
                  showRollOption={showRollOption}
                  showOption={showOption}
                  passwordShown={passwordShown}
                  setPasswordShown={setPasswordShown}
                />
              </div>
            </div>
          </div>

          <div
            className="rounded-3 setting__page tab-pane fade tab-white p-md-1"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <Crmadmins />
          </div>

          <div
            className="rounded-3 setting__page tab-pane fade tab-white p-md-1"
            id="company"
            role="tabpanel"
            aria-labelledby="company-tab"
          >
            <Company />
          </div>

        </div>
      </div>
    </>
  )
}

function CreateNewAccountModal(props) {
  const {
    passwordShown,
    setPasswordShown,
    nameErrors,
    setNameErrors,
    surnameErrors,
    setSurnameErrors,
    showRollOption,
    emailErrors,
    setEmailErrors,
    passwordErrors,
    setPasswordErrors,
    repeatpasswordErrors,
    setRepeatpasswordErrors,
    companyErrors,
    setCompanyErrors,
    showOption,
    open,
    columns,
    onClose,
    onSubmit,
    EditMode,
    selectfieldStatus,
    setfieldStatus,
    setName,
    showName,
    setSurname,
    showSurname,
    setEmail,
    showEmail,
    setPassword,
    showPassword,
    setRepeatpassword,
    showRepeatPassword,
    setCompany,
    showCompany,
    setRole,
    showRole,
    setLanguage,
    showLanguage,
    setTableData,
    tableData,
    rowid,
    setCreateModalOpen,
    row,
    userDetail
  } = props;

  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const { t } = useTranslation();


  const togglePassword = () => {
    // When the handler is invoked
    // chnage inverse the boolean state passwordShown
    setPasswordShown(!passwordShown);
  };


  const handleSubmit = (e) => {
    if (values.name === "") {
      setNameErrors(t('Lang.This is a required field.'));
    } else {
      setNameErrors("");
    }
    if (values.surname === "") {
      setSurnameErrors(t('Lang.This is a required field.'));
    } else {
      setSurnameErrors("");
    }
    if (values.email === "") {
      setEmailErrors(t('Lang.This is a required field.'));
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      setEmailErrors("Enter valid email!");
    } else {
      setEmailErrors("");
    }
    if (values.password === "") {
      setPasswordErrors(t('Lang.This is a required field.'));
    } else {
      setPasswordErrors("");
    }
    if (values.repeatpassword === "") {
      setRepeatpasswordErrors(t('Lang.This is a required field.'));
    } else {
      setRepeatpasswordErrors("");
      if (values.password !== values.repeatpassword) {
        setRepeatpasswordErrors(t("Lang.Password doesn't match!"));
      } else {
        setRepeatpasswordErrors("");
      }
    }

    if (values.company_name === "") {
      setCompanyErrors(t('Lang.This is a required field.'));
    } else {
      setCompanyErrors("");
    }

    if (values.name !== "" && values.surname !== "" && values.email !== "" && values.password !== "" && values.repeatpassword !== "" && values.company_name !== "") {
      onSubmit(values);
      onClose();
    }
  };

  const editAPIcalling = () => {
    const data = {
      name: showName,
      surname: showSurname,
      email: showEmail,
      password: showPassword ? showPassword : '',
      company_id: showCompany,
      role_id: showRole,
      status: selectfieldStatus === true ? "1" : "0",
      lang: showLanguage,
      created_by: userDetail.id,
    };


    WScalling(baseApiUrl + UserUpdate + rowid, "put", data, userDetail.token).then((resp) => {
      if (resp.success === true && resp.data !== "") {
        values.status = resp.data.status;
        values.createdOn = resp.data.createdOn;
        values.role_name = resp.data.role_name;
        values.company_name = resp.data.company_name;
        values.created_by = userDetail.name + ' ' + userDetail.surname;
        values.name = resp.data.name;
        values.surname = resp.data.surname;
        values.email = resp.data.email;
        values.company_id = resp.data.company_id;
        values.role_id = resp.data.role_id;
        values.language = resp.data.lang;
        values.id = resp.data.id
        tableData[row.index] = values;
        setTableData([...tableData]);
        setCreateModalOpen(false);
      }
      // when user change language 
      if (values.id === userDetail.id) {
          localStorage.setItem('i18nextLng', resp.data.lang);
          window.location.reload();
        }
    });
  }

  const handleInput = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <Dialog open={open} className="d-w-400 popup_gape">
      <div className="popups-header d-flex justify-content-between">
        <DialogTitle textAlign="center" className="p-3">
          {EditMode ? t('Lang.Edit user') : t('Lang.Add new user')}
        </DialogTitle>{" "}
        <Button onClick={onClose}>
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3495 9.99858L19.5103 2.85447C19.8238 2.54089 20 2.11558 20 1.67211C20 1.22864 19.8238 0.80333 19.5103 0.489749C19.1967 0.176168 18.7714 0 18.3279 0C17.8844 0 17.4591 0.176168 17.1455 0.489749L10.0014 7.65052L2.85731 0.489749C2.54373 0.176168 2.11842 -3.30411e-09 1.67495 0C1.23148 3.30411e-09 0.806172 0.176168 0.492591 0.489749C0.17901 0.80333 0.00284199 1.22864 0.00284199 1.67211C0.00284199 2.11558 0.17901 2.54089 0.492591 2.85447L7.65336 9.99858L0.492591 17.1427C0.336506 17.2975 0.212618 17.4817 0.128073 17.6846C0.0435283 17.8875 0 18.1052 0 18.3251C0 18.5449 0.0435283 18.7626 0.128073 18.9655C0.212618 19.1684 0.336506 19.3526 0.492591 19.5074C0.647402 19.6635 0.831585 19.7874 1.03452 19.8719C1.23745 19.9565 1.45511 20 1.67495 20C1.89479 20 2.11245 19.9565 2.31538 19.8719C2.51832 19.7874 2.7025 19.6635 2.85731 19.5074L10.0014 12.3466L17.1455 19.5074C17.3003 19.6635 17.4845 19.7874 17.6875 19.8719C17.8904 19.9565 18.1081 20 18.3279 20C18.5477 20 18.7654 19.9565 18.9683 19.8719C19.1713 19.7874 19.3554 19.6635 19.5103 19.5074C19.6663 19.3526 19.7902 19.1684 19.8748 18.9655C19.9593 18.7626 20.0028 18.5449 20.0028 18.3251C20.0028 18.1052 19.9593 17.8875 19.8748 17.6846C19.7902 17.4817 19.6663 17.2975 19.5103 17.1427L12.3495 9.99858Z" fill="white" />
          </svg>
        </Button>
      </div>

      <DialogContent className='p-0'>
        <form onSubmit={handleSubmit} className="add--user">
          <Stack
            sx={{
              marginTop: '20px',
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >

            {EditMode ? (
              <div className="form-group row mx-0 justify-content-center">
                <label className="col-sm-5 px-0 col-form-label">Status</label>
                <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                  <div className="form-check form-switch text-sm-end mb-0 p-0">
                    <Switch
                      checked={selectfieldStatus}
                      name="status"
                      onChange={(event) => {
                        setfieldStatus(event.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-group row mx-0 justify-content-center">
              <label className="col-sm-5 px-0 col-form-label">{t('Lang.Permissions')}</label>
              <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                <select
                  className="form-control select-dropdown"
                  id="role"
                  name="role_name"
                  value={showRole}
                  onChange={(e) => {
                    handleInput(e);
                    setRole(e.target.value)
                    setValues({ ...values, [e.target.name]: e.target.value });
                  }}
                  placeholder="Select field"
                  required
                >

                  {showRollOption.map(rolloption => (
                    <option value={rolloption.id} >{t("Lang." + rolloption.name)}</option>
                  ))
                  }
                </select>
              </div>

              <label className="col-sm-5 px-0 col-form-label">{t('Lang.E-Mail')}</label>
              <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                <Form.Control
                  id="outlined-email-input"
                  label="Email"
                  type="email"
                  name="email"
                  variant="outlined"
                  value={showEmail}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailErrors("")
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }}
                />
                <span className="invalid-feedback d-block">{emailErrors}</span>
              </div>

              <label className="col-sm-5 px-0 col-form-label">{t('Lang.Password')}</label>
              <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                <Form.Control
                  id="outlined-email-input"
                  label="Password"
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  value={showPassword}
                  variant="outlined"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordErrors("")
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }}
                />
                <span className="invalid-feedback d-block">{passwordErrors}</span>
              </div>

              <label className="col-sm-5 px-0 col-form-label">{t('Lang.Repeat Password')}</label>
              <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                <Form.Control
                  id="outlined-email-input"
                  label="RepeatPassword"
                  type={passwordShown ? "text" : "password"}
                  name="repeatpassword"
                  value={showRepeatPassword}
                  variant="outlined"
                  onChange={(e) => {
                    setRepeatpassword(e.target.value);
                    setRepeatpasswordErrors("")
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }}
                />

                <span className="invalid-feedback d-block">{repeatpasswordErrors}</span>
                <Form.Check
                  label={t('Lang.Show password')}
                  onChange={togglePassword}
                />
              </div>

              <label className="col-sm-5 px-0 col-form-label">{t('Lang.Company')}</label>
              <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                <select
                  className="form-control select-dropdown"
                  id="company"
                  name="company_name"
                  value={showCompany}
                  onChange={(e) => {
                    handleInput(e);
                    setCompany(e.target.value)
                    setValues({ ...values, [e.target.name]: e.target.value });
                    setCompanyErrors("");

                    setCompany(e.target.value)
                  }}
                  placeholder="Select field"
                  required
                >
                  <option defaultValue>{t('Lang.Select any company')}</option>

                  {showOption.map(option1 => (
                    // Check if he is super admin or normal admin
                    // if he is normal admin then show only his current company option
                    userDetail.company_id === option1.id || userDetail.id === 1 ?
                      // if (currentUser.company_id === option1.id || currentUser.isSuperAdmin) { } 
                      <option value={option1.id} >{option1.name}</option>
                      :
                      null
                  )
                  )
                  }
                </select>
                <span className="invalid-feedback d-block">{companyErrors}</span>
              </div>

              <label className="col-sm-5 px-0 col-form-label">{t('Lang.Name')}</label>
              <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                <Form.Control
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={showName}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameErrors("")
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }}
                />
                <span className="invalid-feedback d-block">{nameErrors}</span>
              </div>

              <label className="col-sm-5 px-0 col-form-label">{t('Lang.Surname')}</label>
              <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                <Form.Control
                  id="outlined-basic"
                  label="Surname"
                  variant="outlined"
                  name="surname"
                  value={showSurname}
                  onChange={(e) => {
                    setSurname(e.target.value);
                    setSurnameErrors("")
                    setValues({ ...values, [e.target.name]: e.target.value })

                  }}
                />
                <span className="invalid-feedback d-block">{surnameErrors}</span>
              </div>


              <label className="col-sm-5 px-0 col-form-label">{t('Lang.Language')}</label>
              <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                <select
                  className="form-control select-dropdown"
                  id="language"
                  name="language"
                  value={showLanguage}
                  onChange={(e) => {
                    handleInput(e);
                    setLanguage(e.target.value)
                    setValues({ ...values, [e.target.name]: e.target.value });
                  }}
                  placeholder="Select field"
                  required
                >
                  <option value="en">{t('Lang.English')}</option>
                  <option value="de">{t('Lang.German')}</option>
                  <option value="fr">{t('Lang.French')}</option>
                  <option value="it">{t('Lang.Italian')}</option>
                  <option value="gr">{t('Lang.Greek')}</option>
                  <option value="sr">{t('Lang.Serbian')}</option>
                  <option value="hrv">{t('Lang.Croatian')}</option>
                </select>
              </div>
            </div>

          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button
          onClick={() =>
            EditMode ? editAPIcalling() : handleSubmit(values)
          }
          color="secondary"
          className="add-new-field w-100"
          variant="contained"
        >
          {EditMode ? t('Lang.Update') : t('Lang.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

