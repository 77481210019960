import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from 'react-i18next';
import { WScalling } from "./Apicalling";
import TimezoneSelect, { allTimezones } from 'react-timezone-select'

import {
    baseApiUrl,
    companies,
    createCompany
} from "./const";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Switch,
} from "@mui/material";

import { Delete } from "@mui/icons-material";
import { confirmAlert } from "react-confirm-alert";

export let userData = [];

export function Company() {

    let [EditMode, setEditMode] = useState(false);
    const [Companypopup, setCompanypopup] = useState(false);
    const [tableData, setTableData] = useState(() => userData);
    const [userDetail] = useState(JSON.parse(localStorage.getItem('userdetail')));
    const { t } = useTranslation();

    const [CName, setCName] = useState('');
    let [Cmaxobj, setCmaxobj] = useState(1);
    const [Clang, setClang] = useState('en');
    const [rowid, setrowid] = useState("");
    const [row, setRow] = useState("");
    const [Cstatus, setCstatus] = useState(false);
    const [arrdata, setarrdata] = useState(() => []);
    const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
    const [addObjfuc, setObjfuc] = useState(false);


    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: t('Lang.ID'),
                enableColumnOrdering: false,
                size: 150,
            },
            {
                accessorKey: 'status',
                header: t('Lang.Status'),
                size: 70,
                Cell: ({ renderedCellValue, row }) => (
                    <span
                        className={renderedCellValue === "1" || renderedCellValue === 1 ? "active" : "inactive"}
                    ></span>
                ),
            },
            {
                accessorKey: 'name',
                header: t('Lang.Company'),
            },
            {
                accessorKey: 'max_objects',
                header: t('Lang.Max. Objects'),
                size: 90,
            },
            {
                accessorKey: 'objects_count',
                header: t('Lang.Objects'),
                size: 100,
            },
            {
                accessorKey: 'lang',
                header: t('Lang.Language'),
            },
            {
                accessorKey: 'created_at',
                header: t('Lang.Created at'),
            },
            {
                accessorKey: 'updated_at',
                header: t('Lang.Updated at'),
            },
        ]

    );


    useEffect(() => {
        WScalling(baseApiUrl + companies, "get", "", userDetail.token).then((response) => {
            if (response.success === true && response.data !== "") {
                setTableData(response.data);
                setarrdata(response.data)
            }
        });
    }, []);


    const handleDeleteRow = useCallback(
        (row) => {
            confirmAlert({
                title: t('Lang.Delete Company'),
                message: t('Lang.Are you sure to do this.') + ' ' + `${row.getValue("name")}`,
                buttons: [
                    {
                        label: t('Lang.Yes'),
                        onClick: () => {
                            WScalling(baseApiUrl + companies + '/' + row.getValue("id"), "delete", "", userDetail.token).then((resp) => {
                                if (resp.success === true) {
                                    tableData.splice(row.index, 1);
                                    setTableData([...tableData]);
                                    setarrdata(tableData)
                                    return;
                                }
                            });
                        },
                    },
                    {
                        label: t('Lang.No'),
                        onClick: () => {
                            return;
                        },
                    },
                ],
            });
        },
        [tableData],
    );


    const EditCompanyApi = () => {

        if (!checkObjValidation()) {
            setObjfuc(true)
        }
        else {
            const data = {
                "name": CName,
                "timezone": selectedTimezone,
                "max_objects": Cmaxobj,
                "lang": Clang,
                "status": Cstatus === true ? "1" : "0",
            }

            WScalling(baseApiUrl + companies + '/' + rowid, "put", data, userDetail.token).then((response) => {

                const tablelist = {
                    "status": response.data.status,
                    "name": response.data.name,
                    "max_objects": response.data.max_objects,
                    "timezone": response.data.timezone,
                    "lang": response.data.lang,
                    "created_at": response.data.created_at,
                    "updated_at": response.data.updated_at,
                    "id": response.data.id,
                    "objects_count": row.original.objects_count
                }

                tableData[row.index] = tablelist;
                setTableData([...tableData]);
                setarrdata(tableData)
                setCompanypopup(false)
            })
        }
    }


    const SetCompanyFields = (row) => {
        setCName(row.original.name)
        setCmaxobj(row.original.max_objects)
        setClang(row.original.lang)
        setrowid(row.original.id)
        setCstatus(row.original.status === 1 || row.original.status === '1' ? true : false)
        // setSelectedTimezone(row.original.timezone)
    }

    const ResetCompanyFields = () => {
        setCName('')
        setCmaxobj(1)
        setClang('en')
        setrowid('')
        setCstatus(false)
        // setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }

    const CreateCompanyApi = () => {

        if (!checkObjValidation()) {
            setObjfuc(true)
        }

        else {
            const data = {
                "name": CName,
                "timezone": selectedTimezone,
                "max_objects": Cmaxobj,
                "status": '1',
                "lang": Clang
            }

            WScalling(baseApiUrl + createCompany, "post", data, userDetail.token).then((response) => {

                const tablelist = {
                    "status": 1,
                    "name": response.data.name,
                    "max_objects": response.data.max_objects,
                    // "timezone": response.data.timezone,
                    "lang": response.data.lang,
                    "created_at": response.data.created_at,
                    "updated_at": response.data.updated_at,
                    "id": response.data.id,
                    "objects_count": 0
                }

                tableData.push(tablelist);
                setTableData([...tableData]);
                setarrdata(tableData)
                setCompanypopup(false)
            });
        }
    }


    const checkEntryField = (val) => {
        return val === '' ? t('Lang.This is a required field.') : ''
    }


    const checkObjValidation = () => {
        let validationStatus = true;

        if (CName === '') {
            validationStatus = false;
        }

        return validationStatus
    }


    return (
        <>
            <div className="tab-content" id="myTabContent">
                <div
                    className="rounded-3 tab-pane fade show active tab-white px-0 pb-0"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                >
                    <div className="App mt-0">
                        <div className="configure-section setting_page setting-section col-sm">

                            <MaterialReactTable
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        muiTableHeadCellProps: {
                                            align: 'center',
                                        },
                                        // maxSize: 100
                                    },
                                }}

                                muiTableBodyProps={{
                                    sx: {
                                        //stripe the rows, make odd rows a darker color
                                        "& tr:nth-of-type(odd)": {
                                            backgroundColor: "#f5f5f5",
                                        },
                                    },
                                }}

                                muiTableBodyCellProps={{
                                    sx: {
                                        border: "0 solid #e0e0e0", //add a border between columns
                                    },
                                }}

                                localization={{
                                    actions: t('Lang.Actions'),
                                    noRecordsToDisplay: t('Lang.No records to display'),
                                    noResultsFound: t('No results found'),
                                }}

                                columns={columns}
                                data={tableData}

                                enableEditing
                                enableSorting={true}
                                positionActionsColumn="last"
                                enableColumnFilters={false}
                                enableFullScreenToggle={false}
                                enableDensityToggle={false}
                                enableHiding={false}
                                enableGlobalFilterModes
                                positionGlobalFilter="left"
                                enablePagination={false}
                                muiTablePaper={false}
                                enableBottomToolbar={false}
                                enableColumnActions={false}
                                muiSearchTextFieldProps={{
                                    placeholder: t('Lang.Search for company...'),
                                    sx: { minWidth: '18rem' },
                                    size: 'small',

                                }}

                                initialState=
                                {{
                                    density: "compact",
                                    showGlobalFilter: true,
                                    columnVisibility: { id: false, language: false, password: false, repeatpassword: false }
                                }}

                                renderToolbarInternalActions={() => (
                                    <>
                                        <div className="d-flex align-items-center ms-sm-2">
                                            <h4 className="user">{t('Lang.Show only active companies')}</h4>
                                            <Switch
                                                name="status"
                                                onChange={(event) => {
                                                    let arr = [];
                                                    if (event.target.checked === true) {
                                                        arr = arrdata.filter((val) => "1" === val.status || 1 === val.status);
                                                    } else {
                                                        arr = arrdata;
                                                    }
                                                    setTableData(arr);
                                                }}
                                            />
                                        </div>

                                    </>
                                )}

                                renderTopToolbarCustomActions={() => (
                                    <>
                                        {
                                            <Button
                                                className='add-new-field ms-auto'
                                                sx={{ backgroundcolor: 'blue', marginLeft: 'auto', order: 1 }}
                                                onClick={() => {
                                                    ResetCompanyFields()
                                                    setEditMode(false);
                                                    setCompanypopup(true)
                                                }}
                                                variant="contained"
                                            >
                                                {t('Lang.ADD COMPANY')}
                                            </Button>
                                        }
                                    </>
                                )}


                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center", alignItems: 'center' }}>

                                        <Tooltip arrow placement="left" title="Edit">
                                            <IconButton
                                                className="edit-btn p-0"
                                                onClick={() => {
                                                    setRow(row)
                                                    SetCompanyFields(row)
                                                    setEditMode(true);
                                                    setCompanypopup(true);
                                                }}>
                                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.595 2.4706L9.53255 0.408153C9.26338 0.155313 8.91065 0.0102366 8.54148 0.00052145C8.1723 -0.0091937 7.81243 0.11713 7.53033 0.355463L0.75588 7.12991C0.512576 7.37527 0.361084 7.69686 0.326832 8.0407L0.00316363 11.1795C-0.00697624 11.2898 0.00732941 11.4009 0.0450607 11.505C0.0827921 11.6091 0.14302 11.7036 0.221451 11.7817C0.291785 11.8515 0.375198 11.9067 0.466908 11.9441C0.558617 11.9816 0.656818 12.0006 0.75588 12H0.823625L3.96245 11.714C4.30629 11.6797 4.62788 11.5282 4.87324 11.2849L11.6477 4.51046C11.9106 4.23268 12.0527 3.862 12.0428 3.47964C12.033 3.09728 11.8719 2.73443 11.595 2.4706ZM9.03576 4.98467L7.01848 2.96739L8.48628 1.46196L10.5412 3.51687L9.03576 4.98467Z" fill="#999999" />
                                                </svg>
                                                <span className="ms-1">{t('Lang.Edit')}</span>
                                            </IconButton>
                                        </Tooltip>


                                        <Tooltip arrow placement="right" title="Delete">
                                            <IconButton
                                                className="delete-btn p-0"
                                                color="error"
                                                onClick={() => handleDeleteRow(row)}
                                            >
                                                <Delete />
                                                <span>{t('Lang.Delete')}</span>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <Dialog open={Companypopup} className="d-w-400 popup_gape">

                <div className="popup-header d-flex justify-content-between">
                    <DialogTitle textAlign="center" className="p-3">
                        {EditMode ? t('Lang.Edit Company') : t('Lang.Add new company')}
                    </DialogTitle>{" "}
                    <Button onClick={() => setCompanypopup(false)}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3495 9.99858L19.5103 2.85447C19.8238 2.54089 20 2.11558 20 1.67211C20 1.22864 19.8238 0.80333 19.5103 0.489749C19.1967 0.176168 18.7714 0 18.3279 0C17.8844 0 17.4591 0.176168 17.1455 0.489749L10.0014 7.65052L2.85731 0.489749C2.54373 0.176168 2.11842 -3.30411e-09 1.67495 0C1.23148 3.30411e-09 0.806172 0.176168 0.492591 0.489749C0.17901 0.80333 0.00284199 1.22864 0.00284199 1.67211C0.00284199 2.11558 0.17901 2.54089 0.492591 2.85447L7.65336 9.99858L0.492591 17.1427C0.336506 17.2975 0.212618 17.4817 0.128073 17.6846C0.0435283 17.8875 0 18.1052 0 18.3251C0 18.5449 0.0435283 18.7626 0.128073 18.9655C0.212618 19.1684 0.336506 19.3526 0.492591 19.5074C0.647402 19.6635 0.831585 19.7874 1.03452 19.8719C1.23745 19.9565 1.45511 20 1.67495 20C1.89479 20 2.11245 19.9565 2.31538 19.8719C2.51832 19.7874 2.7025 19.6635 2.85731 19.5074L10.0014 12.3466L17.1455 19.5074C17.3003 19.6635 17.4845 19.7874 17.6875 19.8719C17.8904 19.9565 18.1081 20 18.3279 20C18.5477 20 18.7654 19.9565 18.9683 19.8719C19.1713 19.7874 19.3554 19.6635 19.5103 19.5074C19.6663 19.3526 19.7902 19.1684 19.8748 18.9655C19.9593 18.7626 20.0028 18.5449 20.0028 18.3251C20.0028 18.1052 19.9593 17.8875 19.8748 17.6846C19.7902 17.4817 19.6663 17.2975 19.5103 17.1427L12.3495 9.99858Z" fill="white"></path></svg>
                    </Button>
                </div>


                <DialogContent>
                    <form className="add--fields">
                        <Stack
                            sx={{
                                width: "100%",
                                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                                gap: "1.5rem",
                            }}
                        >

                            {
                                EditMode ? (
                                    <div className="form-group row mx-0">
                                        <label className="col-sm-5 px-0 col-form-label">{t('Lang.Status')} </label>
                                        <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                                            <div className="form-check form-switch text-sm-end mb-0 p-0">
                                                <Switch
                                                    checked={Cstatus}
                                                    name="status"
                                                    // value={true}
                                                    onChange={(event) => {
                                                        setCstatus(event.target.checked);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}


                            <div className="form-group row mx-0">
                                <label className="col-sm-5 px-0 col-form-label">
                                    {t('Lang.Company name')}
                                </label>

                                <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                                    <input
                                        value={CName}
                                        className="form-control"
                                        type="text"
                                        onChange={(e) => {
                                            setCName(e.target.value)
                                        }} />
                                    <span className="invalid-feedback d-block">{addObjfuc ? checkEntryField(CName) : ''}</span>
                                </div>

                            </div>

                            <div className="form-group row mx-0">
                                <label className="col-sm-5 px-0 col-form-label">
                                    {t('Lang.Max. Objects')}
                                </label>

                                <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                                    <input
                                        value={Cmaxobj}
                                        className="form-control"
                                        type="number"
                                        onChange={(e) => {
                                            setCmaxobj(e.target.value)
                                        }} />
                                    <span className="invalid-feedback d-block">{addObjfuc ? checkEntryField(Cmaxobj) : ''}</span>

                                </div>
                            </div>



                            <div className="form-group row mx-0">
                                <label className="col-sm-5 px-0 col-form-label">{t('Lang.Language')}</label>
                                <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                                    <select
                                        value={Clang}
                                        className="form-control select-dropdown"
                                        id="language"
                                        name="language"
                                        onChange={(e) => {
                                            setClang(e.target.value)
                                        }}
                                        placeholder="Select field"
                                        required
                                    >
                                        <option value="en">{t('Lang.English')}</option>
                                        <option value="de">{t('Lang.German')}</option>
                                        <option value="fr">{t('Lang.French')}</option>
                                        <option value="it">{t('Lang.Italian')}</option>
                                        <option value="gr">{t('Lang.Greek')}</option>
                                        <option value="sr">{t('Lang.Serbian')}</option>
                                        <option value="hrv">{t('Lang.Croatian')}</option>
                                    </select>
                                </div>
                            </div>


                            <div className="form-group row mx-0">

                                <label className="col-sm-5 px-0 col-form-label"> {t('Lang.Timezone')}</label>

                                <div className="col-lg-7 pe-0 ps-lg-2 ps-0">
                                    <TimezoneSelect
                                        timezones={{
                                            ...allTimezones,
                                            'America/Lima': 'Pittsburgh',
                                            'Europe/Berlin': 'Frankfurt',
                                        }}
                                        value={selectedTimezone}
                                        onChange={(val) => {
                                            setSelectedTimezone(val.value)

                                        }}
                                    />
                                </div>
                            </div>



                        </Stack>
                    </form>
                </DialogContent>


                <DialogActions sx={{ p: "1.25rem" }}>
                    <Button
                        color="secondary"
                        className="add-new-field w-100"
                        variant="contained"
                        onClick={() => {
                            EditMode ? EditCompanyApi() : CreateCompanyApi()
                        }}>
                        {EditMode ? t('Lang.Update') : t('Lang.Save')}
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
};