import React, { useCallback, useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Switch,
} from "@mui/material";
import { Delete, Edit, Menu } from "@mui/icons-material";
import { WScalling } from "./Apicalling";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  baseApiUrl,
  AllFields,
  CreateFieldAPI,
  DeleteFieldAPI,
  SortFieldAPI,
  EditFieldAPI,
} from "./const";

import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';

export let userData = [];

// Default Funcation Call
const ColumnConfiguration = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState(() => userData);
  const [arrdata, setarrdata] = useState(() => []);
  const [validationErrors, setValidationErrors] = useState({});
  let [EditMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState("");
  const [designation, setDesignation] = useState("");
  const [showOption, setShowOption] = useState(false);
  const [rowid, setrowid] = useState("");
  const [row, setrow] = useState("");
  const [userDetail] = useState(JSON.parse(localStorage.getItem('userdetail')));
  const { t } = useTranslation();
  const [Associate, setAssociateVal] = useState('Object');
  const [rolename] = useState(userDetail.role_name === "User" ? true : null);
  let [lastPosition, setlastPosition] = useState(0);


  let [checkBoxValue, setCheckBoxValue] = useState([
    { Option: "Option1", value: "" },
    { Option: "Option2", value: "" },
  ]);

  let [dropDownValue, setdropDownValue] = useState([
    { Option: "Option1", value: "" },
    { Option: "Option2", value: "" },
  ]);


  const [forminitialval, setforminitialval] = useState(
    { associate: Associate, title: '', is_required: '0', status: '0', optionValues: checkBoxValue, op_val: [{ "Send notification": "", "Trigger Status": false }] })


  useEffect(() => {
    const apidata = {
      company_id: userDetail.company_id
    }

    // All Field Api Calling (Show All Field to Table Listing)
    WScalling(baseApiUrl + AllFields, "post", apidata, userDetail.token).then((response) => {
      setarrdata(response.data);

      Object.values(response.data).map(function (val) {
        if (val.order >= lastPosition) {
          lastPosition = val.order;
        }
      });

      setlastPosition(lastPosition);
      setTableData(response.data);
    });
  }, []);

  // CREATE/ADD/RENDER NEW ROW ON SAVE BUTTON CLICK
  const handleCreateNewRow = (value) => {
    const data = {
      type: designation,
      title: value.title,
      // option_values: (designation === 'reminder_date' || designation === 'reminder_hours') ? value.op_val : value.optionValues,
      option_values: (designation === 'reminder_date' || designation === 'reminder_hours') ? [{ ...value.op_val[0], 'Send notification': value.op_val[0]['Send notification'] ? value.op_val[0]['Send notification'] : '0 ' + (designation === 'reminder_date' ? 'days' : 'hours') }] : value.optionValues,
      order: lastPosition + 1,
      status: EditMode ? value.status : "1",
      created_by: userDetail.id,
      is_required: value.is_required,
      company_id: userDetail.company_id,
      associate: value.associate
    };

    if (EditMode) {
      WScalling(baseApiUrl + EditFieldAPI + rowid, "put", data, userDetail.token).then((resp) => {
        tableData[row.index] = resp.data;
        setTableData([...tableData]);
        setarrdata([...tableData]);
        setCreateModalOpen(false);
      });
    }

    else {
      WScalling(baseApiUrl + CreateFieldAPI, "post", data, userDetail.token).then((resp) => {
        setlastPosition(lastPosition + 1);
        tableData.push(resp.data);
        setTableData([...tableData]);
        setarrdata([...tableData]);
        setCreateModalOpen(false);
      });
    }
  };


  // SHOW EDIT FIELD ON EDIT BUTTON
  const showFiled = (Type, row) => {

    setrowid(row.getValue("id"));

    forminitialval.associate = row.original.associate
    forminitialval.title = row.getValue("name")
    forminitialval.is_required = row.original.is_required
    forminitialval.status = row.original.status

    if (Type === 'reminder_date' || Type === 'reminder_hours') {
      forminitialval.op_val = row.original.option_values
    }
    else {
      forminitialval.optionValues = row.original.option_values
    }
  };


  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  // Blank All Field when user click on Edit
  const setAllFieldBlank = () => {

    setdropDownValue([
      { Option: "Option1", value: "" },
      { Option: "Option2", value: "" },
    ])

    setCheckBoxValue([
      { Option: "Option1", value: "" },
      { Option: "Option2", value: "" },
    ])

    setDesignation("");
    forminitialval.title = ""
  };


  const handleDeleteRow = useCallback(
    (row) => {
      confirmAlert({
        title: t('Lang.Delete field'),
        message: <div> {t('Lang.Delete custom field')}<br /> <b> "{row.getValue("name")}" </b> <br /> {'?'} </div>,
        buttons: [
          {
            label: t("Lang.Yes, delete field"),
            className: "yes-confirm",
            onClick: () => {

              WScalling(baseApiUrl + DeleteFieldAPI + row.getValue("id"), "delete", "", userDetail.token).then((resp) => {
                tableData.splice(row.index, 1);
                setTableData([...tableData]);
                setarrdata([...tableData]);
              });
            },
          },
          {
            label: <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg"><path d="M12.3495 9.99858L19.5103 2.85447C19.8238 2.54089 20 2.11558 20 1.67211C20 1.22864 19.8238 0.80333 19.5103 0.489749C19.1967 0.176168 18.7714 0 18.3279 0C17.8844 0 17.4591 0.176168 17.1455 0.489749L10.0014 7.65052L2.85731 0.489749C2.54373 0.176168 2.11842 -3.30411e-09 1.67495 0C1.23148 3.30411e-09 0.806172 0.176168 0.492591 0.489749C0.17901 0.80333 0.00284199 1.22864 0.00284199 1.67211C0.00284199 2.11558 0.17901 2.54089 0.492591 2.85447L7.65336 9.99858L0.492591 17.1427C0.336506 17.2975 0.212618 17.4817 0.128073 17.6846C0.0435283 17.8875 0 18.1052 0 18.3251C0 18.5449 0.0435283 18.7626 0.128073 18.9655C0.212618 19.1684 0.336506 19.3526 0.492591 19.5074C0.647402 19.6635 0.831585 19.7874 1.03452 19.8719C1.23745 19.9565 1.45511 20 1.67495 20C1.89479 20 2.11245 19.9565 2.31538 19.8719C2.51832 19.7874 2.7025 19.6635 2.85731 19.5074L10.0014 12.3466L17.1455 19.5074C17.3003 19.6635 17.4845 19.7874 17.6875 19.8719C17.8904 19.9565 18.1081 20 18.3279 20C18.5477 20 18.7654 19.9565 18.9683 19.8719C19.1713 19.7874 19.3554 19.6635 19.5103 19.5074C19.6663 19.3526 19.7902 19.1684 19.8748 18.9655C19.9593 18.7626 20.0028 18.5449 20.0028 18.3251C20.0028 18.1052 19.9593 17.8875 19.8748 17.6846C19.7902 17.4817 19.6663 17.2975 19.5103 17.1427L12.3495 9.99858Z" fill="white"></path></svg>,
            className: "no-confirm",
            onClick: () => {
              return;
            },
          },
        ],
      });
    },
    [tableData]
  );


  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
      };
    },
    [validationErrors]
  );

  // LIST OF TABLE COLUMN
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t('Lang.ID'),
        enableColumnOrdering: false,
        size: null,
      },
      {
        accessorKey: "order",
        header: t('Lang.Order'),
        enableColumnOrdering: false,
        size: null,
      },
      {
        accessorKey: "status",
        header: t('Lang.Status'),
        size: 60,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <span
              className={renderedCellValue === "1" ? "active" : "inactive"}
            ></span></div>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "name",
        header: t('Lang.Name'),
        enableColumnOrdering: false,
        size: 150,
      },
      {
        accessorKey: "type_title",
        header: t('Lang.Type'),
        size: 140,
        editVariant: "select",
        editSelectOptions: [
          {
            value: "alphanumeric",
            text: "Alphanumeric",
          },
          {
            value: "characters",
            text: "Characters",
          },
          {
            value: "checkbox_multiple",
            text: "Checkbox (Multiple)",
          },
          {
            value: "dropdown",
            text: "Dropdown",
          },
          {
            value: "numerical",
            text: "Numerical",
          },
          {
            value: "reminder_date",
            text: "Reminder date",
          },
          {
            value: "reminder_hours",
            text: "Reminder hours",
          },
          {
            value: "text",
            text: "Text",
          },
          {
            value: "value",
            text: "Value",
          },
          {
            value: "yes_no",
            text: "Yes/No",
          },
        ],
        Cell: ({ renderedCellValue, row }) => (
          <label>{t('Lang.' + renderedCellValue)}</label>
        ),
        // editSelectOptions: useStates
      },
      {
        accessorKey: "triggerStatus",
        header: t('Lang.Trigger Status'),
        size: 90,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        Cell: ({ renderedCellValue, row }) => (
          <label>{(renderedCellValue === "" || renderedCellValue === null || renderedCellValue === 'days') ? '' : (renderedCellValue === "off" || renderedCellValue === "Off") ? t('Lang.Off') : renderedCellValue.split(' ')[0] + " " + t('Lang.' + renderedCellValue.split(' ')[1])}</label>
        ),
      },
      {
        accessorKey: "is_required",
        header: t('Lang.Required'),
        size: 140,
        enableColumnOrdering: false,
        Cell: ({ renderedCellValue, row }) => (
          <label className="black">{renderedCellValue === "1" ? t('Lang.Yes') : t('Lang.No')}</label>
        ),

        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),

        }),
      },
      {
        accessorKey: "createdOn",
        header: t('Lang.Created on'),
        size: 130,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "string",
        }),
      },
      {
        accessorKey: "createdBy",
        header: t('Lang.Created by'),
        Cell: ({ renderedCellValue, row }) => (
          <label className="black">{t('Lang.' + renderedCellValue)}</label>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "string",
          size: 100,
        }),
      },
    ],
    [getCommonEditTextFieldProps]
  );


  return (
    <>
      <div className="tab-content" id="myTabContent">
        <div
          className="rounded-3 configuration__page  tab-pane fade show active tab-white p-0"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab">

          <div className="App mt-0">
            <div className="configure-section configure_page col-sm">

              <MaterialReactTable
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    muiTableHeadCellProps: {
                      align: 'center',
                    },
                  },
                }}

                localization={{
                  actions: t('Lang.Actions'),
                  noRecordsToDisplay: t('Lang.No records to display'),
                  noResultsFound: t('No results found'),
                }}

                muiTableProps={{
                  sx: {
                    tableLayout: 'fixed',
                  },
                }}

                muiTableBodyProps={{
                  sx: {
                    //stripe the rows, make odd rows a darker color
                    '& td:nth-of-type(odd)': {
                      backgroundColor: '#f5f5f5',
                    },
                  },
                }}

                muiTableBodyCellProps={{
                  sx: {
                    backgroundColor: '#f5f5f5',
                    borderRight: '2px solid #e0e0e0', //add a border between columns
                  },
                }}
                columnResizeMode="onEnd"
                columns={columns}
                data={tableData}
                muiTableBodyProps={{
                  sx: {
                    //stripe the rows, make odd rows a darker color
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#f5f5f5",
                    },
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: "0 solid #e0e0e0", //add a border between columns
                  },
                }}
                editingMode="modal" //default
                enableColumnOrdering={false}
                enableEditing={userDetail.role_name === 'User' ? false : true} // If Set False then action column hide.
                enableRowOrdering
                enableRowDragging
                enableSorting={true}
                enableColumnFilters={false}
                onEditingRowCancel={handleCancelRowEdits}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                initialState={{
                  density: "compact",
                  showGlobalFilter: true,
                  columnVisibility: { id: false, order: false },
                }}

                enableColumnResizing={false}
                enableRowActions={false}
                enableGlobalFilterModes
                positionGlobalFilter="left"
                enablePagination={false}
                enableBottomToolbar={false}
                positionActionsColumn="last"
                muiSearchTextFieldProps={{
                  placeholder: t('Lang.Search...'),
                }}

                icons={{
                  DragHandleIcon: () => <IconButton className="menu_drop"> <Menu /> </IconButton>,
                  MoreVertIcon: () => null
                }}

                muiTableBodyRowDragHandleProps={({ table }) => ({
                  onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    if (draggingRow) {
                      tableData.splice(
                        hoveredRow.index,
                        0,
                        tableData.splice(draggingRow.index, 1)[0]
                      );

                      setTableData([...tableData]);
                      setarrdata([...tableData]);

                      const datas = {
                        id: draggingRow.getValue("id"),
                        order: hoveredRow.index + 1,
                        data: tableData,
                      };

                      WScalling(baseApiUrl + SortFieldAPI, "post", datas, userDetail.token).then(
                        (resp) => {

                        }
                      );
                    }
                  },
                })}

                renderRowActions={({ row, table }) => (
                  row.getValue('createdBy') === 'System' ? null :

                    <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center", alignItems: 'center' }}>
                      <Tooltip arrow placement="left" title={t('Lang.Edit')}>
                        <IconButton
                          className="edit-btn p-0"
                          onClick={() => {
                            setrow(row);
                            setDesignation(row.original.type);
                            showFiled(row.original.type, row);
                            setEditMode(true);
                            setCreateModalOpen(true);
                          }}
                        >
                          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.595 2.4706L9.53255 0.408153C9.26338 0.155313 8.91065 0.0102366 8.54148 0.00052145C8.1723 -0.0091937 7.81243 0.11713 7.53033 0.355463L0.75588 7.12991C0.512576 7.37527 0.361084 7.69686 0.326832 8.0407L0.00316363 11.1795C-0.00697624 11.2898 0.00732941 11.4009 0.0450607 11.505C0.0827921 11.6091 0.14302 11.7036 0.221451 11.7817C0.291785 11.8515 0.375198 11.9067 0.466908 11.9441C0.558617 11.9816 0.656818 12.0006 0.75588 12H0.823625L3.96245 11.714C4.30629 11.6797 4.62788 11.5282 4.87324 11.2849L11.6477 4.51046C11.9106 4.23268 12.0527 3.862 12.0428 3.47964C12.033 3.09728 11.8719 2.73443 11.595 2.4706ZM9.03576 4.98467L7.01848 2.96739L8.48628 1.46196L10.5412 3.51687L9.03576 4.98467Z" fill="#999999" />
                          </svg>
                          <span className="ms-1">{t('Lang.Edit')}</span>
                        </IconButton>
                      </Tooltip>

                      <Tooltip arrow placement="right" title={t('Lang.Delete')}>
                        <IconButton
                          className="delete-btn p-0"
                          color="error"
                          onClick={() => handleDeleteRow(row)}>
                          <Delete />
                          <span>{t('Lang.Delete')}</span>
                        </IconButton>
                      </Tooltip>
                    </Box>
                )}

                options={{
                  renderRowActionsColumnIndex: -1,
                  tableLayout: "fixed"
                }}


                renderTopToolbarCustomActions={() => (
                  <>
                    <div className="d-flex justify-content-start align-items-center w-md-auto ms-sm-2">
                      <h4 className="user">{t('Lang.Show only active fields')}</h4>

                      <Switch
                        name="status"
                        onChange={(event) => {
                          let arr = [];
                          if (event.target.checked === true) {
                            arr = arrdata.filter((val) => "1" === val.status);
                          } else {
                            arr = arrdata;
                          }
                          setTableData(arr);
                        }}
                      />
                    </div>

                    {
                      rolename ? null :
                        <Button
                          color="secondary"
                          className="add-new-field ms-auto"
                          onClick={() => {
                            setEditMode(false);
                            setCreateModalOpen(true);
                            setAllFieldBlank();

                          }}
                          variant="contained"
                        >
                          {t('Lang.Add field')}
                        </Button>
                    }

                  </>
                )}
              />

            </div>

            <CreateNewAccountModal
              setCheckBoxValue={setCheckBoxValue}
              checkBoxValue={checkBoxValue}
              showOption={showOption}
              setDesignation={setDesignation}
              designation={designation}
              EditMode={EditMode}
              onSubmit={handleCreateNewRow}
              onClose={() => setCreateModalOpen(false)}
              columns={columns}
              open={createModalOpen}
              t={t}
              forminitialval={forminitialval}
            />
          </div>
        </div>
      </div>
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
function CreateNewAccountModal(props) {
  const {
    forminitialval,
    open,
    columns,
    onClose,
    onSubmit,
    EditMode,
    designation,
    setDesignation,
    showOption,
    checkBoxValue,
    setCheckBoxValue,
    t,
  } = props;

  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {})
  );


  const onfillup = (values) => {
    onSubmit(values);
  };


  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('Lang.This is a required field.')),
  });

  const handlePop = (optionValuesObj, i) => {
    optionValuesObj.values.optionValues.splice(i, 1);
    setValues(optionValuesObj);
  };

  return (

    <Dialog open={open} className="d-w-400 popup_gape">
      <div className="popup-header d-flex justify-content-between">
        <DialogTitle textAlign="center" className="p-3">
          {EditMode ? t('Lang.Edit field') : t('Lang.Add new fields')}
        </DialogTitle>{" "}
        <Button onClick={onClose}>
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3495 9.99858L19.5103 2.85447C19.8238 2.54089 20 2.11558 20 1.67211C20 1.22864 19.8238 0.80333 19.5103 0.489749C19.1967 0.176168 18.7714 0 18.3279 0C17.8844 0 17.4591 0.176168 17.1455 0.489749L10.0014 7.65052L2.85731 0.489749C2.54373 0.176168 2.11842 -3.30411e-09 1.67495 0C1.23148 3.30411e-09 0.806172 0.176168 0.492591 0.489749C0.17901 0.80333 0.00284199 1.22864 0.00284199 1.67211C0.00284199 2.11558 0.17901 2.54089 0.492591 2.85447L7.65336 9.99858L0.492591 17.1427C0.336506 17.2975 0.212618 17.4817 0.128073 17.6846C0.0435283 17.8875 0 18.1052 0 18.3251C0 18.5449 0.0435283 18.7626 0.128073 18.9655C0.212618 19.1684 0.336506 19.3526 0.492591 19.5074C0.647402 19.6635 0.831585 19.7874 1.03452 19.8719C1.23745 19.9565 1.45511 20 1.67495 20C1.89479 20 2.11245 19.9565 2.31538 19.8719C2.51832 19.7874 2.7025 19.6635 2.85731 19.5074L10.0014 12.3466L17.1455 19.5074C17.3003 19.6635 17.4845 19.7874 17.6875 19.8719C17.8904 19.9565 18.1081 20 18.3279 20C18.5477 20 18.7654 19.9565 18.9683 19.8719C19.1713 19.7874 19.3554 19.6635 19.5103 19.5074C19.6663 19.3526 19.7902 19.1684 19.8748 18.9655C19.9593 18.7626 20.0028 18.5449 20.0028 18.3251C20.0028 18.1052 19.9593 17.8875 19.8748 17.6846C19.7902 17.4817 19.6663 17.2975 19.5103 17.1427L12.3495 9.99858Z" fill="white"></path></svg>
        </Button>
      </div>

      <DialogContent>


        <Formik
          initialValues={forminitialval}
          onSubmit={onfillup}
          validationSchema={validationSchema}
        >

          {({ values, handleChange, setFieldValue }) => (

            <Form className="add--fields">
              <Stack
                sx={{
                  width: "100%",
                  minWidth: { xs: "300px", sm: "360px", md: "400px" },
                  gap: "1.5rem",
                }}
              >

                <h5 className="text-center title-select mb-3">
                  {showOption === false ? t("Lang.Please select type of field") : null}
                </h5>


                {/* Status */}
                {
                  EditMode ? (
                    <div className="form-group row mx-0">
                      <label className="col-sm-5 px-0 col-form-label"> {t('Lang.Status')}</label>
                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                        <div className="form-check form-switch text-sm-end mb-0 p-0">
                          <Switch
                            checked={values.status === '1' ? true : false}
                            name="status"
                            // value={true}
                            onChange={(event) => {
                              setFieldValue(`status`, event.target.checked ? "1" : "0")
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}


                <div className="form-group row mx-0">
                  <label className="col-sm-5 px-0 col-form-label">{t('Lang.Type')}</label>

                  <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                    <select
                      disabled={EditMode}
                      className="form-control select-dropdown"
                      id="designation"
                      name="type"
                      value={designation}
                      type="text"
                      onChange={(e) => {
                        // handleInput(e);
                        // setAllFieldBlank();
                        values.status = "0"
                        values.is_required = "0"
                        values.optionValues = [
                          { Option: "Option1", value: "" },
                          { Option: "Option2", value: "" },
                        ]
                        values.op_val = [{ "Send notification": "", "Trigger Status": false }]
                        setDesignation(e.target.value);
                        // setFieldValue(`title`, '')
                      }}
                      placeholder="Select field"
                      required
                    >

                      <option disabled={true} value="" defaultValue>{t('Lang.Select any field')}</option>
                      <option className="odd" value="alphanumeric">
                        {t('Lang.Alphanumeric')}
                      </option>
                      <option className="even" value="characters">
                        {t('Lang.Characters')}
                      </option>
                      <option className="odd" value="checkbox_multiple">
                        {t('Lang.Checkbox (Multiple)')}
                      </option>
                      <option className="even" value="dropdown">
                        {t('Lang.Dropdown')}
                      </option>
                      <option className="odd" value="numerical">
                        {t('Lang.Numerical')}
                      </option>

                      <option className="even" value="reminder_hours">
                        {t('Lang.Hours')}
                      </option>

                      <option className="odd" value="reminder_date">
                        {t('Lang.Date')}
                      </option>

                      <option className="odd" value="text">
                        {t('Lang.Text')}
                      </option>
                      <option className="even" value="value">
                        {t('Lang.Value')}
                      </option>
                      <option className="odd" value="yes_no">
                        {t('Lang.Yes/No')}
                      </option>
                    </select>
                  </div>
                </div>



                {/* Title And Associate Here showing */}
                {designation !== '' && (
                  <>

                    {/* Associate is here */}
                    <div className="form-group row mx-0">
                      <label className="col-sm-5 px-0 col-form-label">{t('Lang.Associate')}</label>

                      <div className="control col-sm-7 pe-0 ps-sm-2 ps-0">
                        <select
                          className="form-control select-dropdown"
                          id="designation"
                          name="associate"
                          value={values.associate}
                          type="text"
                          onChange={handleChange}
                          required
                        >
                          <option className="odd" value="Object">
                            {t('Lang.Object')}
                          </option>
                          <option className="even" value="Entries">
                            {t('Lang.Entries')}
                          </option>
                        </select>
                      </div>
                    </div>


                    {/* Title is here */}
                    <div className="form-group row mx-0">
                      <label className="col-sm-5 px-0 col-form-label">{t('Lang.Title')}</label>
                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                        <Field
                          autocomplete="off"
                          id="title"
                          name="title"
                          className="form-control"
                          type={designation === 'numerical' ? "text" : "text"}
                          // value={title}
                          placeholder={
                            (designation === 'alphanumeric' || designation === 'text') ? t('Lang.Enter title') :
                              designation === 'characters' ? t('Lang.First Name') :
                                (designation === 'checkbox_multiple' || designation === 'dropdown') ? t('Lang.Used for') :
                                  (designation === 'numerical' || designation === 'reminder_hours' || designation === 'reminder_date') ? t('Lang.Enter title here...') :
                                    ''
                          }
                          onChange={handleChange}
                        />
                        <ErrorMessage className="invalid-feedback d-block" name="title" component="div" />
                      </div>
                    </div>
                  </>
                )}


                {/* Checkbox_multiple  &&  Dropdown */}
                {(designation === 'checkbox_multiple' || designation === 'dropdown') && (
                  <>
                    {
                      values.optionValues.map((element, index) => (
                        <div className="form-group row mx-0">
                          <label className="col-sm-5 col-form-label px-0">
                            {t('Lang.Option')} {index + 1}
                          </label>

                          <div className="col-sm-7 pe-0 ps-sm-2 ps-0 options-block">
                            <Field
                              autocomplete="off"
                              className="form-control"
                              placeholder={t('Lang.Enter option')}
                              name={`optionValues.${index}.value`}
                              type="text"
                              // value={checkBoxValue[index].value}
                              onChange={handleChange}
                            />

                            {index > 1 && (
                              <Delete 
                                className="delete-btn option-delete-btn p-0 deletebtn"
                                onClick={() => handlePop({values}, index)}
                                />
                            )}
                          </div>
                        </div>
                      ))
                    }

                    <div className="form-group row mx-0">
                      <label className="col-sm-5 col-form-label px-0"></label>
                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                        <Button
                          color="secondary"
                          className="add-new-field w-100"
                          variant="contained"
                          onClick={() => {
                            values.optionValues.push({ Option: "Option" + (values.optionValues.length + 1), value: "" })
                            setCheckBoxValue([...checkBoxValue, { Option: "", value: "" }]);
                          }}
                        >
                          + {t('Lang.Add option')}
                        </Button>
                      </div>
                    </div>
                  </>
                )}


                {/* Date & Hour */}
                {(designation === 'reminder_date' || designation === 'reminder_hours') && (
                  <>
                    <div className="form-group row mx-0">
                      <label className="col-sm-5 col-form-label px-0">
                        {t('Lang.Trigger Status')}
                      </label>
                      <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                        <div className="form-check form-switch text-sm-end mb-0 p-0">
                          <Switch
                            checked={values.op_val[0]['Trigger Status']}
                            name={`op_val[0].Trigger Status`}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="form-group row mx-0">
                      <label className="col-sm-5 col-form-label px-0">
                        {
                          designation === 'reminder_date' ? t('Lang.Send notification') : t('Lang.Trigger for notif.')
                        }
                      </label>
                      <div className="col-sm-7 pe-0 d-flex align-items-center">

                        <Field
                          autocomplete="off"
                          className="form-control me-2"
                          id="show_dropdown"
                          name={`op_val[0]['Send notification']`}
                          type="text"
                          value={designation === 'reminder_date' 
                            ? (values.op_val[0]['Send notification'] ? values.op_val[0]['Send notification'].split('days')[0].trim() : '') 
                            : (values.op_val[0]['Send notification'] ? values.op_val[0]['Send notification'].split('hours')[0].trim() : '')}
                          placeholder={designation === 'reminder_date' ? t('Lang.Enter days here') : t('Lang.Enter hours here')}
                          // onChange={handleChange}
                          onChange={(event) => {
                            setFieldValue(`op_val[0]['Send notification']`, designation === 'reminder_date' ? event.target.value + ' ' + 'days' : event.target.value + ' ' + 'hours')
                          }}
                        />
                        {" "}
                        <span className="form_label"> {designation === 'reminder_date' ? t('Lang.days') : t('Lang.hours')}  </span>
                      </div>
                    </div>

                  </>
                )}


                {/* is_required */}
                {designation !== '' && (
                  <div className="form-group row mx-0">
                    <label className="col-sm-5 col-form-label px-0">
                      {t('Lang.Required (Setup)')}
                    </label>
                    <div className="col-sm-7 pe-0 ps-sm-2 ps-0">
                      <div className="form-check form-switch text-sm-end mb-0 p-0">
                        <Switch
                          checked={values.is_required === '1' ? true : false}
                          name="is_required"
                          onChange={(event) => {
                            setFieldValue(`is_required`, event.target.checked ? "1" : "0")
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}


                <button
                  variant="contained"
                  color="secondary"
                  className="add-new-field w-100"
                  type="submit">

                  {EditMode ? t('Lang.Update') : t('Lang.Save')}

                </button>


              </Stack>
            </Form>
          )}

        </Formik>

      </DialogContent>
    </Dialog >
  );
}

export default ColumnConfiguration;