import React from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "../img/icon_dashboard.svg";
import NotificationIcon from "../img/icon_notification.svg";
import SettingIcon from "../img/icon_setting.svg";
import TableIcon from "../img/icon_table.svg";
import { useLocation } from "react-router-dom";

function Sidebar() {

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <>
      <div className="col-sm-auto sidebar p-0">
        <div className="d-flex flex-sm-column flex-row flex-nowrap align-items-center">
          <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 align-items-center">
            <li className="nav-item w-100">
              <Link
                to="/home"
                className={splitLocation[1] === "home" ? "active nav-link py-3 px-2" : "nav-link py-3 px-2 "}
                title=""
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-original-title="Home"
              >
                <img
                  src={DashboardIcon}
                  alt="Dashboard"
                  className="icon-sidebar"
                />
              </Link>
            </li>

            <li className="w-100">
              <Link
                to="/notify"
                className={splitLocation[1] === "notify" ? "active nav-link py-3 px-2" : "nav-link py-3 px-2 "}
                title=""
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-original-title="Orders"
              >
                <img
                  src={NotificationIcon}
                  alt="Dashboard"
                  className="icon-sidebar"
                />
              </Link>
            </li>
            <li className="w-100">
              <Link
                to="/configure"
                className={splitLocation[1] === "configure" ? "active nav-link py-3 px-2" : "nav-link py-3 px-2 "}
                title=""
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-original-title="Orders"
              >
                <img src={TableIcon} alt="Dashboard" className="icon-sidebar" />
              </Link>
            </li>
            <li className="w-100">
              <Link
                to="/setting"
                className={splitLocation[1] === "setting" ? "active nav-link py-3 px-2" : "nav-link py-3 px-2 "}
                title=""
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-original-title="Products"
              >
                <img
                  src={SettingIcon}
                  alt="Dashboard"
                  className="icon-sidebar"
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
