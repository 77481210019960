import React, { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from 'react-i18next';
import { WScalling } from "./Apicalling";

import {
  UserSuperAdmin,
  baseApiUrl
} from "./const";

export let userData = [];

// Default Funcation Call
// const Crmadmin = () => {
export function Crmadmins() {

  const [tableData, setTableData] = useState(() => userData);
  const [userDetail] = useState(JSON.parse(localStorage.getItem('userdetail')));
  const { t } = useTranslation();
  const data = { company_id: userDetail.company_id };

  useEffect(() => {
    WScalling(baseApiUrl + UserSuperAdmin, "post", data, userDetail.token).then((resp) => {
      if (resp.success === true && resp.data !== "") {
        setTableData(resp.data);
      }
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t('Lang.ID'),
        enableColumnOrdering: false,
        size: 150,
      },
      {
        accessorKey: 'status',
        header: t('Lang.Status'),
        size: 70,
        Cell: ({ renderedCellValue, row }) => (
          <span
            className={renderedCellValue === "1" ? "active" : "inactive"}
          ></span>
        ),
      },
      {
        accessorKey: 'company_name',
        header: t('Lang.Company'),
      },
      {
        accessorKey: 'name',
        header: t('Lang.Name'),
        size: 90,
      },
      {
        accessorKey: 'surname',
        header: t('Lang.Surname'),
        size: 100,
      },
      {
        accessorKey: 'email',
        header: t('Lang.E-Mail'),
      },
      {
        accessorKey: 'role_name',
        header: t('Lang.Permissions'),
        Cell: ({ renderedCellValue, row }) => (
          <label className="black">{t('Lang.' + renderedCellValue)}</label>
       ),
      },
      {
        accessorKey: 'createdOn',
        header: t('Lang.Created on'),
      },
      {
        accessorKey: 'created_by',
        header: t('Lang.Created by'),
      },

      {
        accessorKey: 'language',
        header: t('Lang.Language'),
      },

      {
        accessorKey: 'password',
        header: t('User.Password'),
      },
      {
        accessorKey: 'repeatpassword',
        header: t('RepeatPassword'),
      },


    ]

  );


  return (
    <>
      <div className="tab-content" id="myTabContent">
        <div
          className="rounded-3 tab-pane fade show active tab-white px-0 pb-0"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div className="App mt-0">
            <div className="configure-section setting_page setting-section col-sm">
              <MaterialReactTable
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    muiTableHeadCellProps: {
                      align: 'center',
                    },
                    size: 120,
                  },
                }}

                localization={{
                
                  noRecordsToDisplay: t('Lang.No records to display'),
                  noResultsFound: t('No results found'),
              }}

                columns={columns}
                data={tableData}
                muiTableBodyProps={{
                  sx: {
                    //stripe the rows, make odd rows a darker color
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#f5f5f5",
                    },
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    border: "0 solid #e0e0e0", //add a border between columns
                  },
                }}
                enableSorting={true}
                positionActionsColumn="last"
                enableColumnFilters={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                initialState={{ density: "compact", showGlobalFilter: false, columnVisibility: { id: false, language: false, password: false, repeatpassword: false } }}
                enableFilters={false}
                enablePagination={false}
                muiTablePaper={false}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableTopToolbar={false}

              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// export default Crmadmins;
