import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import React from "react";
import Sitelogo from "../img/logo.svg";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import {
  baseApiUrl, NotificationCount
} from "./const";

import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { WScalling } from "./Apicalling";

export default function Signin() {
  const [email, setUserName] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [userDetail] = useState(JSON.parse(localStorage.getItem('userdetail')));
  const [keeploggin] = useState(JSON.parse(localStorage.getItem('loggeduser')));
  let [pagetitle] = useState(() => t('Lang.Form360') + ' - ' + t('Lang.Login'));
  let [keeplogin, setkeeplogin] = useState(false);
  const [Noticount, setNoticount] = useState("0");


  useEffect(() => {
    if (userDetail !== null) {
      navigate('/home');
    }
    else {
      if (keeploggin) {
        setUserName(keeploggin.Email)
        setPassword(keeploggin.Password)
        setkeeplogin(keeploggin.Status)
      }
    }
  }, []);

  const loginUser = (credentials) => {

    fetch(baseApiUrl + 'user/login', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    }).then((response) => {
      return response.json().then((res) => {
        if (res.success === true) {
          WScalling(baseApiUrl + NotificationCount, "post", { company_id: res.data.company_id }, res.data.token ).then((response) => {
            localStorage.setItem('noticount', JSON.stringify(response.data.count));
            setNoticount(response.data.count)
          });
          localStorage.setItem('userdetail', JSON.stringify(res.data));
          localStorage.setItem('i18nextLng', res.data.lang);
          if (keeplogin) {
            localStorage.setItem('loggeduser', JSON.stringify({ Email: email, Password: password, Status: true }));
          }
          else {
            sessionStorage.setItem('keepLoggedIn', true)
            localStorage.removeItem('loggeduser')
          }

          Swal.fire({
            title: t("Lang.Success!"),
            text: res.message === "User login successfully." ? t("Lang.User login successfully.") : res.message,
            icon: "success",
            confirmButtonText: t("Lang.OK")
          }).then((value) => {
            // localStorage.setItem('i18nextLng', res.data.lang);
            navigate('/home');
            i18n.changeLanguage(res.data.lang);
          });          
        } else {
          Swal.fire({
            title: t("Lang.Failed!"),
            text: res.message === "Please enter valid email and password." ? t("Lang.Please enter valid email and password.") : t('Lang.Validation Error.'),
            icon: "error",
            confirmButtonText: t("Lang.OK")
          });          
        }
      });
    });
  };

  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>

      <div className="login-page box_wrap_info">

        {
          userDetail === null ?

            <div className="container">
              <div className="row justify-content-center m-0">
                <div className="bg-white login_form">
                  <div className="row m-0 p-0">
                    <div className="col-md-12 p-0">
                      <div className="form-left">
                        <div className="text-center">
                          <img src={Sitelogo} alt="" className="logo" />
                        </div>

                        <div className="d-flex align-items-center flex-wrap p-0 justify-content-between">
                          <div className="col-md-12 p-0">
                            <form
                              id="login-form"
                              action=""
                              className="row m-0 p-0">
                              <div className="col-12 p-0 m_10">
                                <label className="col-form-label"> {t('Lang.E-mail')} </label>
                                <div className="input-group">
                                  <input
                                    value={email}
                                    type="Email"
                                    onChange={(e) => setUserName(e.target.value)}
                                    className="form-control"
                                    // placeholder="Enter Email"
                                    placeholder={t("Lang.Enter Email")}
                                  />
                                </div>
                              </div>

                              <div className="col-12 p-0 m_10">
                                <label className="col-form-label"> {t('Lang.Password')}  </label>
                                <div className="input-group">
                                  <input
                                    value={password}
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-control"
                                    // placeholder="Enter Password"
                                    placeholder={t('Lang.Enter Password')}
                                  />
                                </div>
                              </div>

                              <div className="d-flex align-items-center flex-wrap p-0 justify-content-between">
                                <div className="col-md-6 p-0 m_10">
                                  <div className="form-check m-0">
                                    <input
                                      checked={keeplogin}
                                      className="form-check-input"
                                      type="checkbox"
                                      id="invalidCheck"
                                      onChange={(e) => {
                                        setkeeplogin(e.target.checked)
                                      }}
                                    />
                                    <label className="form-check-label m-0" htmlFor="invalidCheck"> {t('Lang.Keep me logged in')} </label>
                                  </div>
                                </div>

                                <div className="col-md-6 p-0 m_10">
                                  <Link to='/forgot-password' className="float-end text_forgot"
                                  >
                                    {t('Lang.Forgot Password?')}
                                  </Link>
                                </div>
                              </div>

                              <div className="col-sm-12 p-0">
                                <Button
                                  onClick={() => {
                                    if (
                                      email === "" ||
                                      email === undefined ||
                                      password === "" ||
                                      password === undefined
                                    ) {
                                      Swal.fire({
                                        title: t("Lang.Failed!"),
                                        text: t("Lang.Please enter email and password"),
                                        icon: "error",
                                        confirmButtonText: t("Lang.OK")
                                      });
                                    } else {
                                      loginUser({
                                        email: email,
                                        password: password,
                                      });
                                    }
                                  }}
                                  type="button"
                                  className="add-new-field"
                                >
                                  {t('Lang.login')}
                                </Button>
                              </div>

                            </form>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            null
        }
      </div>
    </>
  );
}
