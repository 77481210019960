import { Helmet } from "react-helmet";
import React, { useState } from "react";
import Sitelogo from "../img/logo.svg";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import {
    baseApiUrl,
    ResetPass
} from "./const";
import { useSearchParams, useNavigate } from "react-router-dom";

const Resetpass = () => {
    let navigate = useNavigate();
    const [queryParameters] = useSearchParams()
    const [Password, setpassword] = useState('');
    const [Confpassowrd, setconfpassowrd] = useState('');
    const [Email] = useState(queryParameters.get("email"));
    const [Token] = useState(queryParameters.get("token"));
    const [validate, setvalidate] = useState(false);
    const { t } = useTranslation();

    const ResetPassApi = () => {

        const validation = checkEmptyField()

        if (!validation) {
            setvalidate(true)
        }
        else {
            const data = {
                email: Email,
                password: Password,
                confirm_password: Confpassowrd,
                token: Token
            }

            if (Password === Confpassowrd) {
                fetch(baseApiUrl + ResetPass, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }).then((response) => {
                    return response.json().then((res) => {
                        if (res.success === true) {
                            Swal.fire("Success!", res.message, "success").then((value) => {
                                navigate('/')
                            });
                        } else {
                            Swal.fire("Failed!", res.message, "error");
                        }
                    });
                });
            }
            else {
                Swal.fire("Failed!", 'Password and Confirm Password not match.!', "error");
            }
        }
    }

    const EmptyCheck = (val) => {
        return val === '' ? t('Lang.This is a required field.') : ''
    }

    const checkEmptyField = () => {
        let validationStatus = true;
        if (Password === '' || Confpassowrd === '') {
            validationStatus = false;
        }
        return validationStatus
    }

    return (
        <>
            <Helmet>
                {/* <title>'Forgotpass'</title> */}
            </Helmet>

            <div className="forgot-page box_wrap_info">
                <div className="container">
                    <div className="row justify-content-center m-0">
                        <div className="bg-white login_form">
                            <div className="row m-0 p-0">
                                <div className="col-md-12 p-0">
                                    <div className="form-left">
                                        <div className="text-center">
                                            <img src={Sitelogo} alt="" className="logo" />
                                        </div>

                                        <div className="d-flex align-items-center flex-wrap p-0 justify-content-between">
                                            <div className="col-md-12 p-0">
                                                <form
                                                    id="login-form"
                                                    action=""
                                                    className="row m-0 p-0">

                                                    <div className="col-12 p-0 m_10">
                                                        <label className="col-form-label"> Enter new password </label>
                                                        <div className="input-group">
                                                            <input
                                                                value={Password}
                                                                type="password"
                                                                onChange={(e) => setpassword(e.target.value)}
                                                                className="form-control"
                                                                placeholder="Enter new Password"
                                                            />
                                                        </div>
                                                        <span className="invalid-feedback d-block">{validate ? EmptyCheck(Password) : ''}</span>
                                                    </div>


                                                    <div className="col-12 p-0 m_10">
                                                        <label className="col-form-label"> Confirm new password  </label>
                                                        <div className="input-group">
                                                            <input
                                                                value={Confpassowrd}
                                                                type="password"
                                                                onChange={(e) => setconfpassowrd(e.target.value)}
                                                                className="form-control"
                                                                placeholder="Enter Confirm Password"
                                                            />
                                                        </div>
                                                        <span className="invalid-feedback d-block">{validate ? EmptyCheck(Confpassowrd) : ''}</span>
                                                    </div>


                                                    <div className="col-sm-12 p-0">
                                                        <Button
                                                            onClick={() => {
                                                                ResetPassApi()
                                                            }}
                                                            type="button"
                                                            className="add-new-field"
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}


export default Resetpass;