import React from "react";
import axios from "axios";


export function WScalling(Url, method, requestdata, token) {

  const headers = {
    AccessToken: token,
  };

  if (method === "post") {
    return axios
      .post(Url, requestdata, {
        headers: headers,
      })
      .then((resp) => {
        if (resp.data.success === true && resp.data.data !== "") {
          return resp.data;
        }
      });
  } else if (method === "get") {
    return axios
      .get(Url, {
        headers: headers,
      })
      .then((resp) => {
        if (resp.data.success === true && resp.data.data !== "") {
          return resp.data;
        }
      });
  } else if (method === "delete") {
    return axios
      .delete(Url, {
        headers: headers,
      })
      .then((resp) => {
        if (resp.data.success === true && resp.data.data !== "") {
          return resp.data;
        }
      });
  }
  else if (method === "put") {
    return axios
      .put(Url, requestdata, {
        headers: headers,
      })
      .then((resp) => {
        if (resp.data.success === true && resp.data.data !== "") {
          return resp.data;
        }
      });
  }
}
