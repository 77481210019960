// import logo from './logo.svg';
import "./App.css";
import React from 'react';
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Home from "./components/Home";
import Notification from "./components/Notification";
import Forgotpass from "./components/Forgotpass";
import Resetpass from "./components/Resetpass";

import { Routes, Route, Outlet } from "react-router-dom";
import Configuration from "./components/Configuration";
import Settingpage from "./components/Settingpage";
import Login from "./components/Login";
import { useNavigate } from "react-router-dom";

let details;

export default function App() {
  let navigate = useNavigate();

  const SidebarLayout = () => {
    details = localStorage.getItem('userdetail')

    if (details === null) {
      navigate('/')
    }
    else {
      return <>
        <header className="top-bar">
          <Navbar></Navbar>
        </header>
        <div className="container-fluid main-content">
          <div className="row flex-md-nowrap flex-wrap">
            <Sidebar></Sidebar>
            <Outlet />
          </div></div>
      </>
    }
  }


  return (
    <>
      <Routes>
        <Route element={<SidebarLayout />}>
          <Route path="home" element={<Home />} />
          <Route path="notify" element={<Notification />} />
          <Route path="configure" element={<Configuration />} />
          <Route path="setting" element={<Settingpage />} />
        </Route>
        <Route exact path="/" element={<Login />} />
        <Route path="forgot-password" element={<Forgotpass />} />
        <Route path="reset-password" element={<Resetpass />} />
      </Routes>
    </>
  );
}





